/* eslint-disable no-underscore-dangle */
import React, { useMemo } from 'react'
import { withTheme } from 'styled-components'

import {
  titleCase,
  stringNotEmpty,
  ensureArray,
  ensureObject,
} from '@agnostack/lib-core'

import { withBreakpoint } from '../components/hocs'
import { getParentCategory, getTitle } from '../gatsby'
import { useContextGlobal, GLOBAL_PARAMS } from '../util'
import {
  SEO,
  TreeMenu,
  StickyAccordion,
  NavigationButtons,
} from '../components/atoms'

import TemplatedPage from '../components/molecules/TemplatedPage'

export default withTheme(({ pageContext }) => {
  const [{ [GLOBAL_PARAMS.LISTING]: listingContext }] = useContextGlobal()

  const ContentNav = withBreakpoint(StickyAccordion, TreeMenu)
  const {
    node: {
      frontmatter: {
        format: pageFormat,
        template,
        canonicalPath,
        parentPath,
        imagePath,
        path,
        keywords,
        description,
        siteMetadata: {
          tagline,
        } = {},
      } = {},
    } = {},
    paths = [],
  } = ensureObject(pageContext)

  const articleKeywords = `faq, frequent questions${keywords ? `, ${keywords}` : ''}`

  const { filteredPaths, navigation, navigationCategory } = useMemo(() => {
    const _filteredPaths = Object.entries(paths)
      .reduce((_paths, [section, pages]) => ({
        ..._paths,
        ...stringNotEmpty(section) && {
          [section]: pages,
        },
      }), {})

    const _navigationCategory = getParentCategory({ edge: pageContext, paths })
    const subPaths = ensureArray(_filteredPaths[_navigationCategory])

    const _navigation = ((subPaths.length <= 1)
      ? null
      : (
        <NavigationButtons
          path={(stringNotEmpty(parentPath) && stringNotEmpty(path)) ? path : ensureObject(subPaths[0]).path}
          subPaths={subPaths}
        />
      )
    )

    return {
      filteredPaths: _filteredPaths,
      navigation: _navigation,
      navigationCategory: _navigationCategory,
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paths, listingContext])

  const title = useMemo(() => {
    const _title = getTitle({ context: pageContext })
    return (_title === titleCase(template)) ? `${_title}: ${tagline}` : _title
  }, [pageContext, tagline, template])

  return (
    <>
      <SEO
        title={title}
        description={description}
        keywords={articleKeywords}
        path={path}
        imagePath={imagePath}
        canonicalPath={canonicalPath}
        type="article"
      />
      <TemplatedPage
        data={pageContext}
        headline="Frequently"
        tagline="asked questions."
        format={pageFormat || 'medium'}
        navigation={navigation}
        parentPath={parentPath}
        title={title}
        path={path}
      >
        <ContentNav
          path={path}
          parentPath={parentPath}
          paths={filteredPaths}
          category={navigationCategory}
        />
      </TemplatedPage>
    </>
  )
})
