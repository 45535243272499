/* eslint-disable max-len */
import styled from 'styled-components'
import hexToRgba from 'hex-to-rgba'

export const TreeWrapper = styled.div`
  & > ul {
    ${({ theme: { space } = {} }) => `
      padding: ${space.xxs} ${space.xs}!important;
    `}
  }
`

// NOTE: this component is based on tailwind-ui Toggle, may want to add TW at some point
// for now duplicated w/ bridge to Theme via styled-components
export const DebuggerWell = styled.div`
  ${({ theme: { space, colors, palette, fontSizes, borderStyles, borderRadii } = {} }) => `
    position: relative;
    cursor: pointer;
    text-align: left;
    border: 1px ${borderStyles.solid} ${palette[colors.neutralHue][300]};
    border-radius: ${borderRadii.md};
    font-size: ${fontSizes.md};
    padding: ${space.xxs} ${space.sm};
    margin: ${space.sm} auto 0;
  `}
`

export const ScreenReader = styled.div`
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border-width: 0;
`

export const SwitchGroup = styled.div`
  ${({ theme: { space } = {} }) => `
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: ${space.xs};
    padding-bottom: ${space.xs};
  `}
  `

export const SwitchLabel = styled.span`
  ${({ theme: { colors, palette, fontWeights } = {} }) => `
    user-select: none;
    margin-left: 0.75rem;
    font-size: .875rem;
    line-height: 1.25rem;
    font-weight: ${fontWeights.medium};
    color: ${palette[colors.neutralHue][800]};
  `}
`

export const Switch = styled.button.attrs(({ checked }) => ({
  role: 'switch',
  'aria-checked': checked,
}))`
  ${({ checked, theme: { colors, palette, borderRadii } = {} }) => `
    position: relative;
    display: inline-flex;
    flex-shrink: 0;

    height: 1.5rem;
    width: 2.75rem;
    border-width: ${borderRadii.sm};
    border-color: ${hexToRgba(palette.black, 0.1)};
    border-radius: 9999px;

    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
    transition-timing-function: cubic-bezier(.4,0, .2,1);
    transition-duration: .2s;

    --tw-ring-shadow: 0 0 ${palette.black};
    --tw-ring-offset-shadow: 0 0 ${palette.black};
    --tw-shadow: inset 0 ${borderRadii.sm} ${borderRadii.md} 0 ${hexToRgba(palette.black, 0.05)};
    --tw-shadow-color: ${hexToRgba(palette.black, 0.05)};
    --tw-shadow-colored: inset 0 ${borderRadii.sm} ${borderRadii.md} 0 var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 ${palette.black}), var(--tw-ring-shadow, 0 0 ${palette.black}), var(--tw-shadow);
    background-color: ${checked ? palette[colors.primaryHue][700] : palette[colors.neutralHue][300]}
  `}
`

export const SwitchButton = styled.span.attrs(() => ({
  'aria-hidden': true,
}))`
  ${({ checked, theme: { colors, palette } = {} }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;

    transition-property: opacity;
    transition-timing-function: cubic-bezier(.4,0, .2,1);
    transition-duration: .15s;
  
    ${checked ? `
      opacity: 1;
      transition-timing-function: cubic-bezier(.4, 0, 1,1);
      transition-duration: .2s;
    ` : `
      transition-timing-function: cubic-bezier(0,0, .2,1);
      transition-duration: .1s;
    `}

    & > svg {
      height: 0.75rem;
      width: 0.75rem;
      color: ${palette[colors.primaryHue][600]};
    }
  `}
`

export const SwitchBackground = styled.span.attrs(() => ({
  'aria-hidden': true,
}))`
  ${({ checked, theme: { colors, palette } = {} }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;

    transition-property: opacity;
    transition-timing-function: cubic-bezier(.4,0, .2,1);
    transition-duration: .15s;

    ${checked ? `
      opacity: 0;
      transition-timing-function: cubic-bezier(0,0, .2,1);
      transition-duration: .1s;
    ` : `
      transition-timing-function: cubic-bezier(.4, 0, 1,1);
      transition-duration: .2s;
    `}

    & > svg {
      height: 0.75rem;
      width: 0.75rem;
      color: ${palette[colors.neutralHue][500]};
    }
  `}
`

export const SwitchContainer = styled.span`
  ${({ checked, theme: { palette } = {} }) => `
    pointer-events: none;
    display: inline-block;
    position: relative;
    max-width: 70%;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 9999px;
    background-color: ${palette.white};

    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(.4, 0, .2,1);
    transition-duration: .2s;

    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246/0.5);
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    --tw-shadow: 0 4px 6px -1px ${hexToRgba(palette.black, 0.1)}, 0 2px 4px -2px ${hexToRgba(palette.black, 0.1)};
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow,0 0 #0000);

    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;

    ${checked ? `
      --tw-translate-x: 1.25rem;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    ` : `
      --tw-translate-x: 0px;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    `}
  `}
`
