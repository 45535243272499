import React, { useEffect, useRef, useState } from 'react'

import gsap from 'gsap'

import theme from '../../../theme'
import { ColorPanel } from '../../../styles/core'

const ColorSlider = ({ activeFormat }) => {
  let topPanel = useRef(null)
  const [previousFormat, setPreviousFormat] = useState(activeFormat)

  useEffect(() => {
    gsap.fromTo(
      topPanel,
      {
        width: 0,
      },
      {
        width: '100%',
        ease: 'power2.inOut',
        duration: 0.6,
        onComplete: () => {
          setPreviousFormat(activeFormat)
        },
      }
    )
  }, [activeFormat])

  return (
    <>
      <ColorPanel
        style={{
          backgroundColor: `${theme.formats[previousFormat].background}`,
        }}
      />
      <ColorPanel
        style={{
          backgroundColor: `${theme.formats[activeFormat].background}`,
        }}
        top
        ref={(el) => {
          topPanel = el
        }}
      />
    </>
  )
}

export default ColorSlider
