import React, { useEffect, useMemo, useState } from 'react'
import { withTheme } from 'styled-components'
import { graphql } from 'gatsby'
import { Grid, Row, Col } from '@zendeskgarden/react-grid'

import { useAsyncMemo } from '@agnostack/lib-utils-react'
import {
  getData,
  stringNotEmpty,
  arrayNotEmpty,
  ensureArray,
  ensureObject,
  PRODUCT_NAMES,
  PLAN_PROFESSIONAL,
} from '@agnostack/lib-core'

import { Tagline, HeroTitle, ColorOverlay } from '../styles/core'
import { Heading, Section, SEO } from '../components/atoms'
import { useContextGlobal, GLOBAL_PARAMS, LISTINGS, LISTING_NAMES } from '../util'

import AppsCalculator from '../components/molecules/AppsCalculator'
import Pricing from '../components/molecules/Pricing'
import LearnMore from '../components/molecules/LearnMore'
import Hexagon from '../images/hexagon.svg'

export const query = graphql`
  query pricingPageQuery {
    site {
      siteMetadata {
        tagline
      }
    }
  }
`

export default withTheme(({
  data: {
    site: {
      siteMetadata: {
        tagline,
      } = {},
    } = {},
  } = {},
}) => {
  const [selectedTier, setSelectedTier] = useState()
  const [{ [GLOBAL_PARAMS.LISTING]: listingContext }] = useContextGlobal()

  const pricingData = useAsyncMemo(async () => (
    getData('pricingData')
  ), [])

  const { pricingTiers, selectedListing } = useMemo(() => {
    const _selectedListing = listingContext || LISTING_NAMES[0]
    const { exclude, mutate } = ensureObject(LISTINGS[_selectedListing])
    let _pricingTiers = ensureArray(pricingData)

    if (arrayNotEmpty(exclude)) {
      _pricingTiers = _pricingTiers.filter(({ plan }) => !ensureArray(exclude).includes(plan))
    }

    if (mutate) {
      _pricingTiers = _pricingTiers.map(mutate)
    }

    return {
      pricingTiers: _pricingTiers,
      selectedListing: _selectedListing,
    }
  }, [listingContext, pricingData])

  useEffect(() => {
    if (arrayNotEmpty(pricingTiers)) {
      const defaultTier = pricingTiers.find(({ plan }) => (
        plan === selectedTier?.plan
      )) ?? pricingTiers.find(({ plan }) => (
        plan === PRODUCT_NAMES[PLAN_PROFESSIONAL]
      )) ?? pricingTiers?.[0]

      setSelectedTier(defaultTier)
    }
  }, [selectedTier?.plan, pricingTiers])

  return (
    <>
      <SEO title={`Pricing: ${tagline}`} />
      <Section format="accent1" mode="halfscreen">
        <ColorOverlay opacity="0.5">
          <Hexagon />
        </ColorOverlay>
        <Grid>
          <Row>
            <Col md={6}>
              <Heading tag="1">
                <HeroTitle>Simple pricing</HeroTitle>
                <Tagline>for every size brand.</Tagline>
              </Heading>
            </Col>
          </Row>
        </Grid>
      </Section>
      <AppsCalculator
        id="apps-calculator"
        mode="overlay"
        max="xl"
        backdrop="light"
        format="default"
        tiers={pricingTiers}
        selectedTier={selectedTier}
        selectedListing={selectedListing}
        onSelect={({ tier }) => {
          if (stringNotEmpty(tier)) {
            setSelectedTier(tier)
          }
        }}
      >
        agnoStack Apps
      </AppsCalculator>
      <Pricing
        id="tiers"
        format="light"
        tiers={pricingTiers}
        selectedTier={selectedTier}
        selectedListing={selectedListing}
        onSelect={setSelectedTier}
      />
      <LearnMore
        id="learn-more"
        mode="overlay"
        max="sm"
        format="light"
      />
    </>
  )
})
