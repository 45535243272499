/* eslint-disable react/jsx-closing-bracket-location */
import AOS from 'aos'
import React, { useEffect } from 'react'

import { cleanObject, stringEmptyOnly } from '@agnostack/lib-core'

import { GlobalStyles } from './src/styles/global'
import { GlobalContextProvider, GLOBAL_PARAMS } from './src/util'
import './src/styles/fonts.css'

const envProps = cleanObject({
  [GLOBAL_PARAMS.DISPLAY]: process.env.GATSBY_DISPLAY_ENV,
  [GLOBAL_PARAMS.LISTING]: process.env.GATSBY_DEFAULT_LISTING_ENV,
}, false, stringEmptyOnly)

const WrappedRoot = ({ element, ...renderProps }) => {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 700,
      disable: 'phone',
      easing: 'ease-out-cubic',
    })
  }, [])

  return (
    <GlobalContextProvider {...renderProps} {...envProps}>
      <GlobalStyles />
      {element}
    </GlobalContextProvider>
  )
}

export const wrapRootElement = ({ element, ...renderProps }) => (
  <WrappedRoot element={element} {...renderProps} />
)

export const shouldUpdateScroll = () => false
