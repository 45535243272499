import { createGlobalStyle } from 'styled-components'

// TODO: should this use zendesk bedrock?
import './reset.css'
import theme from '../theme'

export const GlobalStyles = createGlobalStyle`
  html.modal.closed {
    overflow: unset!important;
  }
  html {
    font-family: sans-serif;
    background-color: ${theme.body.background};
    color: ${theme.body.color};
  }
  body {
    line-height: 1.35;
    font-family: ${theme.typography.body.default};
    font-size: ${theme.typography.sizes.default};
    overflow-x: hidden;
  }
  h1 {
    margin-bottom: .5em;
    font-weight: 300;
  }
  h2, h4, h6 {
    text-transform: uppercase;
    font-weight: 800;
  }
  h1, h2 {
    line-height: 1;
  }
  h1, h3 {
    font-weight: 300;
  }
  h2, h3, h4, h5, h6 {
    line-height: 1.1;
    margin-bottom: .8em;
  }
  pre {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 10px 15px;
    overflow: auto;
    white-space: pre-wrap;
  }
  code {
    background-color: #f8f8f8;
    margin: -1px;
    padding: 2px 2px 1px 2px;
  }
  .XL {
    font-size: ${theme.typography.sizes.XL};
  }
  .LG {
    font-size: ${theme.typography.sizes.LG};
  }
  .MD {
    font-size: ${theme.typography.sizes.MD};
  }
  .SM {
    font-size: ${theme.typography.sizes.SM};
  }
  .XS {
    font-size: ${theme.typography.sizes.XS};
  }
  .hide-sm {
    @media (max-width: ${theme.breakpoints.sm}) {
      display: none;
    }
  }
  a.anchor {
    cursor: pointer;

    &.before {
      z-index: +1;
    }
  }
  div.wrapper {
    position: relative;

    & > .anchor > svg {
      visibility: hidden;
    }

    &:hover > .anchor > svg {
      visibility: visible;
    }
  }
  .table {
    border: 1px solid;
    padding: 4px;
  }
`
