/* eslint-disable react/no-danger */
import React, { useMemo } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import {
  isTypeEnhanced,
  objectNotEmpty,
  deepmerge,
  safeParse,
  ensureObject,
  ensureString,
  DEFAULT_LOCALE,
} from '@agnostack/lib-core'

import { useContextGlobal } from '../../../util'

const getParseableStructuredData = (structuredData) => {
  if (isTypeEnhanced(structuredData, 'object')) {
    return JSON.stringify(structuredData)
  }

  if (isTypeEnhanced(structuredData, 'string')) {
    try {
      return JSON.stringify(safeParse(structuredData))
    } catch (_ignore) {
      return '{}'
    }
  }

  return '{}'
}

const SEO = ({
  structuredData: _structuredData,
  title: _title,
  keywords,
  author,
  description,
  canonicalPath,
  parentPath,
  imagePath,
  path,
  meta = [],
  lang = DEFAULT_LOCALE,
  type = 'website',
  ...metaTags
}) => {
  const [{ modalsState }] = useContextGlobal()

  const {
    site: {
      siteMetadata: {
        tagline,
        siteUrl,
        title: siteTitle,
        description: siteDescription,
        author: siteAuthor,
        keywords: siteKeywords,
        structuredData: siteStructuredData,
      },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            author
            title
            tagline
            keywords
            description
            structuredData
          }
        }
      }
    `
  )

  const modalOpen = useMemo(() => (
    Object.values(ensureObject(modalsState)).some(({ visible }) => visible)
  ), [modalsState])

  const metaKeywords = !keywords ? siteKeywords : keywords.concat(', ', siteKeywords)
  const metaDescription = description || siteDescription
  const title = _title || tagline

  const structuredData = useMemo(() => {
    try {
      const siteData = JSON.parse(getParseableStructuredData(siteStructuredData))
      const pageData = JSON.parse(getParseableStructuredData(_structuredData))
      const mergedData = deepmerge(siteData, pageData)

      return JSON.stringify(mergedData)
    } catch (_ignore) {
      return undefined
    }
  }, [_structuredData, siteStructuredData])

  const pageMeta = [
    {
      name: 'keywords',
      content: metaKeywords,
    },
    {
      name: 'description',
      content: metaDescription,
    },
    {
      name: 'author',
      content: author || siteAuthor,
    },
    {
      name: 'og:locale',
      content: lang,
    },
    {
      property: 'og:site_name',
      content: siteTitle,
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: metaDescription,
    },
    {
      property: 'og:type',
      content: type,
    },
    {
      name: 'twitter:site',
      content: siteAuthor,
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:creator',
      content: author || siteAuthor,
    },
    {
      name: 'twitter:title',
      content: title,
    },
    {
      name: 'twitter:description',
      content: metaDescription,
    }
  ]
  if (imagePath) {
    const imageURL = ensureString(imagePath).startsWith('http')
      ? imagePath
      : `${siteUrl}${imagePath}`
    pageMeta.push({
      property: 'og:image',
      content: imageURL,
    }, {
      property: 'twitter:image',
      content: imageURL,
    })
  }
  if (canonicalPath || path) {
    pageMeta.push({
      property: 'og:url',
      content: `${siteUrl}${canonicalPath || path}`,
    })
  }
  if (objectNotEmpty(metaTags)) {
    Object.entries(metaTags).forEach(([tagProperty, tagContent]) => {
      pageMeta.push({
        property: tagProperty,
        content: tagContent,
      })
    })
  }

  return (
    <Helmet
      title={title}
      titleTemplate={`%s .: ${siteTitle}`}
      meta={pageMeta.concat(meta)}
      htmlAttributes={{
        lang,
        // NOTE: this is to fix the garden modal component focusjail not unsetting overflow when closed
        class: `modal ${modalOpen ? 'open' : 'closed'}`,
      }}
      script={structuredData ? [{
        type: 'application/ld+json',
        innerHTML: structuredData,
      }] : []}
      link={canonicalPath ? [{
        rel: 'canonical',
        href: `${siteUrl}${canonicalPath}`,
      }] : []}
    />
  )
}

export default SEO
