import { window, navigator } from 'browser-monads-ts'

import { stringNotEmptyOnly } from '@agnostack/lib-core'

export const copyToClipboard = async (text) => {
  // eslint-disable-next-line eqeqeq
  if ((navigator?.clipboard?.writeText != undefined) && stringNotEmptyOnly(text)) {
    return navigator.clipboard.writeText(text)
      .then(() => (
        text
      ))
      .catch((warn) => {
        console.warn('Error copying text to clipboard', warn)
        return undefined
      })
  }

  return undefined
}

export const getClipped = async (focus) => {
  let clipped

  // eslint-disable-next-line eqeqeq
  if (navigator?.clipboard?.readText != undefined) {
    try {
      if (focus) {
        window.focus?.()
      }
      clipped = await navigator.clipboard.readText()
    } catch (ignore) {
      console.warn('Ignoring getClipped error', ignore)
    }
  }

  return clipped
}
