/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'

import {
  isTrue,
  isTypeEnhanced,
  objectNotEmpty,
  ensureObject,
  ensureArray,
  arrayEmpty,
} from '@agnostack/lib-core'
import { delay, triggerNotification } from '@agnostack/lib-utils-js'
import { CopyableTreeView } from '@agnostack/lib-utils-react'

import { useContextState } from '../../hooks'
import {
  TreeWrapper,
  DebuggerWell,
  ScreenReader,
  SwitchGroup,
  SwitchLabel,
  Switch,
  SwitchButton,
  SwitchBackground,
  SwitchContainer,
} from './ContextDebugger.style'

const ToggleSwitch = ({ checked, onChange, children }) => {
  const [isChecked, setIsChecked] = useState(checked)

  useEffect(() => {
    if (isTypeEnhanced(onChange, 'function')) {
      onChange(isChecked)
    }
  }, [isChecked])

  const toggleChecked = () => {
    setIsChecked(!isChecked)
  }

  return (
    <SwitchGroup className="w-full flex py-3 items-center justify-center" onClick={toggleChecked}>
      <Switch
        checked={isChecked}
        className={classNames('relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none shadow-inner shadow-gray-400', {
          'bg-indigo-600': isChecked,
          'bg-gray-200': !isChecked,
        })}
      >
        <ScreenReader>{children}</ScreenReader>
        <SwitchContainer
          checked={isChecked}
          className={classNames('pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow-md shadow-gray-400 transform ring-0 transition ease-in-out duration-200', {
            'translate-x-5': isChecked,
            'translate-x-0': !isChecked,
          })}
        >
          <SwitchBackground
            checked={isChecked}
            className={classNames('absolute inset-0 h-full w-full flex items-center justify-center transition-opacity', {
              'opacity-0 ease-out duration-100': isChecked,
              'opacity-100 ease-in duration-200': !isChecked,
            })}
          >
            <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
              <path
                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </SwitchBackground>
          <SwitchButton
            checked={isChecked}
            className={classNames(
              isChecked ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
              'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
            )}
          >
            <svg fill="currentColor" viewBox="0 0 12 12">
              <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
            </svg>
          </SwitchButton>
        </SwitchContainer>
      </Switch>
      <SwitchLabel className="ml-3">
        <span className="text-sm font-medium text-gray-900 select-none">{children}</span>
      </SwitchLabel>
    </SwitchGroup>
  )
}

const ContextDebugger = ({
  title = 'Display Context',
  setShowDebugger: _setShowDebugger,
  showDebugger: _showDebugger,
  contextState: _contextState,
  togglers: _togglers,
  valueOnly,
  className,
  children,
  enabled,
  theme,
  Icon,
  onChange = () => {},
  ...data
}) => {
  const [showDebugger, setShowDebugger] = useState(false)
  const [contextState] = useContextState() ?? []
  const {
    I18n,
    appClient,
  } = contextState

  const handleSetShowDebugger = (updatedValue) => {
    _setShowDebugger?.(updatedValue)
    setShowDebugger(updatedValue)
  }

  const handleCopied = async (copiedText) => {
    // eslint-disable-next-line eqeqeq
    if (copiedText != undefined) {
      await delay(200)
      triggerNotification(appClient, I18n.t('notifications.copied-to-clipboard'))
      await delay(1000)
    }
  }

  useEffect(() => {
    setShowDebugger(_showDebugger)
  }, [_showDebugger])

  useEffect(() => {
    onChange(showDebugger)
  }, [showDebugger])

  const { persistentTogglersEntries, togglerEntries } = useMemo(() => (
    Object.entries(ensureObject(_togglers)).reduce((_togglerGroups, togglerEntry) => {
      if (togglerEntry?.[1]?.persistent) {
        return {
          ..._togglerGroups,
          persistentTogglersEntries: [
            ...ensureArray(_togglerGroups?.persistentTogglersEntries),
            togglerEntry
          ],
        }
      }

      return {
        ..._togglerGroups,
        togglerEntries: [
          ...ensureArray(_togglerGroups?.togglerEntries),
          togglerEntry
        ],
      }
    }, {})
  ), [_togglers])

  if (!contextState || (!enabled && arrayEmpty(persistentTogglersEntries))) {
    return null
  }

  // TODO: add manage feature flag toggles?
  return (
    <DebuggerWell
      className={classNames('context-debugger flex flex-col items-center px-4 py-2 bg-yellow-100 border-2 cursor-pointer', className)}
      onClick={(e) => {
        e?.stopPropagation?.()
      }}
    >
      {ensureArray(persistentTogglersEntries).map(([key, { value, onChange: _onChange = () => {} }], index) => (
        <ToggleSwitch
          key={`${title}-debugger-peristent-toggler-${index}`}
          checked={isTrue(value)}
          onChange={_onChange}
        >
          {key}
        </ToggleSwitch>
      ))}
      {enabled && (
        <>
          {ensureArray(togglerEntries).map(([key, { value, onChange: _onChange = () => {} }], index) => (
            <ToggleSwitch
              key={`${title}-debugger-toggler-${index}`}
              checked={isTrue(value)}
              onChange={_onChange}
            >
              {key}
            </ToggleSwitch>
          ))}
          {children}
          <ToggleSwitch checked={showDebugger} onChange={handleSetShowDebugger}>
            {title}
          </ToggleSwitch>
          {showDebugger && (
            // eslint-disable-next-line jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events
            <TreeWrapper role="tree" className="px-3 py-1 mb-3 w-8/12 max-w-3xl bg-white rounded-lg shadow-md shadow-gray-400 cursor-auto" onClick={(e) => e?.stopPropagation?.()}>
              <CopyableTreeView
                data={_contextState ?? contextState}
                valueOnly={valueOnly}
                theme={theme}
                Icon={Icon}
                onCopied={handleCopied}
              />
              {objectNotEmpty(data) && (
                <CopyableTreeView
                  valueOnly={valueOnly}
                  theme={theme}
                  data={data}
                  Icon={Icon}
                  onCopied={handleCopied}
                />
              )}
            </TreeWrapper>
          )}
        </>
      )}
    </DebuggerWell>
  )
}

export default ContextDebugger
