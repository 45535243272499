import React, { useEffect, useRef, useState } from 'react'
import { withTheme } from 'styled-components'

import { ensureNumeric, ensureObject } from '@agnostack/lib-core'

import { ToastChild, ToastWrapper } from './Notifications.style'

const ToastContainer = withTheme(({
  appearance,
  transitionDuration,
  transitionState,
  theme: {
    space,
  } = {},
  ...renderProps
}) => {
  const [height, setHeight] = useState()
  const elementRef = useRef(null)

  useEffect(() => {
    if (transitionState === 'entered') {
      const { offsetHeight } = ensureObject(elementRef.current)
      if (offsetHeight) {
        setHeight(`${offsetHeight + ensureNumeric(space?.xs)}px`)
      }
    } else if (transitionState === 'exiting') {
      setHeight('0px')
    }
  }, [transitionState])

  return (
    <ToastWrapper
      ref={elementRef}
      data-height={height}
      transitionDuration={transitionDuration}
    >
      <ToastChild
        transitionState={transitionState}
        transitionDuration={transitionDuration}
        {...renderProps}
      />
    </ToastWrapper>
  )
})

export default ToastContainer
