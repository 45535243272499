import React, { useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { withTheme } from 'styled-components'
import { globalHistory } from '@reach/router'

import { hashCode, DATALAYER } from '@agnostack/lib-utils-js'

import {
  useContextGlobal,
  GLOBAL_ACTIONS,
  TITLE_SUBSCRIBE,
} from '../../../util'

import {
  ModalWrapper,
  ModalContainer,
} from '../../../styles/core'
import ContactForm from '../ContactForm'
import StatefulModal from '../../molecules/StatefulModal'
import Heading from '../Heading'
import {
  BellEmoji,
  StyledButton,
  ButtonWrapper,
} from './SubscribeButton.style'

const {
  HUBSPOT_CONTACT_PROPERTIES_FIRST_NAME,
  HUBSPOT_CONTACT_PROPERTIES_LAST_NAME,
} = DATALAYER

const fields = [
  {
    label: 'First Name',
    value: HUBSPOT_CONTACT_PROPERTIES_FIRST_NAME,
  },
  {
    label: 'Last Name',
    value: HUBSPOT_CONTACT_PROPERTIES_LAST_NAME,
  }
]

const SubscribeButton = ({ theme, onClick = () => {}, children = TITLE_SUBSCRIBE, ...renderProps }) => {
  const [, dispatch] = useContextGlobal()

  const guid = useMemo(() => (
    globalHistory?.location?.pathname
      ? `${hashCode(globalHistory.location.pathname)}-subscribe`
      : uuidv4()
  ), [])

  return (
    <>
      <StatefulModal center id={guid} background={theme.colors.tertiary}>
        <ModalWrapper>
          <ModalContainer>
            <Heading tag="4" size="LG">
              Sign up to receive release updates!
            </Heading>
            <p>
              We will send you notifications containing details on each new release to stay up to date on the latest features, fixes and more.
            </p>
            <ContactForm
              type="agnoStack WebSite Release Notes"
              fields={fields}
            />
          </ModalContainer>
        </ModalWrapper>
      </StatefulModal>
      <StyledButton
        {...renderProps}
        onClick={(e) => {
          dispatch({
            type: GLOBAL_ACTIONS.DISPATCH_MODAL,
            id: guid,
            visible: true,
            title: children,
          })
          onClick(e)
        }}
      >
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <BellEmoji aria-label="Subscribe">
          🔔
        </BellEmoji>
        <ButtonWrapper>
          {children}
        </ButtonWrapper>
      </StyledButton>
    </>
  )
}

export default withTheme(SubscribeButton)
