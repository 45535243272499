import {
  ensureArray,
  objectEmpty,
  ensureObject,
  METADATA_PROP,
} from '@agnostack/lib-core'

export const addMetaProp = (_object, { providerSetId, metaInfo, metaData: _metaData }) => {
  if (!_object) {
    return {}
  }

  const metaData = _metaData || {
    ...providerSetId && {
      [METADATA_PROP]: {
        [providerSetId]: ensureObject(metaInfo),
      },
    },
  }

  return {
    ..._object,
    ...ensureObject(metaData),
  }
}

export const getMetaProviderSet = ((data, metaKey = METADATA_PROP) => {
  if (objectEmpty(data)) {
    return {}
  }

  const { [metaKey]: _meta } = data
  const meta = ensureObject(_meta)
  const [providerSetId, _providerSetMeta] = ensureArray(
    Object.entries(meta)[0]
  )
  const providerSetMeta = ensureObject(_providerSetMeta)

  return {
    providerSetId,
    metaInfo: providerSetMeta,
    metaData: { [metaKey]: meta },
  }
})
