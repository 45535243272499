import styled from 'styled-components'

import { SVGIcon } from '../icons'

export const TreeWrapper = styled.div`
  & > ul {
    ${({ theme: { space } = {} }) => `
      padding: ${space.xxs} ${space.xs}!important;
    `}
  }
`

export const StyledSVGIcon = styled(SVGIcon).attrs(() => ({
  size: 'small',
  display: 'inline-flex',
}))`
  transition: opacity 0s;
  ${({ isCopied }) => `
    opacity: ${isCopied ? '1' : '.1'};
    svg {
      cursor: ${isCopied ? 'not-allowed' : 'pointer'};
    }
  `}
`

export const CopyWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  ${({ theme: { space } = {} }) => `
    gap: ${space.xxs};
  `}

  &:hover > ${StyledSVGIcon} {
    opacity: 1;
    transition: opacity .3s cubic-bezier(0.1, 0, 0.6, 1);
  }
`

export const DisplayValue = styled.div`
  text-indent: 0;
  cursor: pointer;
`
