/* eslint-disable no-use-before-define */
import equals from 'fast-deep-equal/es6'
import semverGT from 'semver/functions/gt'
import semverParse from 'semver/functions/parse'
import { encode as encodeHTML, decode as decodeHTML } from 'html-entities'

import {
  isType,
  isString,
  isTypeEnhanced,
  isNumericNegatable,
  isNumericOnly,
  isParseable,
  ensureArray,
  safeParse,
  lowercase,
  uppercase,
  titleCase,
  unsnakecase,
  stringEmpty,
  stringNotEmpty,
  stringEmptyOnly,
  ensureLeadingDollar,
  ensureStringOnly,
  ensureString,
  ensureObject,
  ensureDateTime,
  convertDateTime,
  objectNotEmpty,
  cleanObject,
  toFormatted,
  COLORS,
  ELLIPSE,
  DEFAULT_LOCALE,
  DATE_FORMAT_TRACKING,
  DATE_FORMAT_LONG,
  DATE_FORMAT_MED,
  LOCALE_FORMATS,
} from '@agnostack/lib-core'

import { DATA_ITEM_TYPES, TIMELINE_EVENT_ADVANCED_TYPES } from './constants'

const REGEXP_ESCAPECHARS = /[\\^$.*+?()[\]{}|]/g

// LOCAL FUNCTIONS

const dateStartsWithAny = (_value, comparison) => {
  const _date = ensureDateTime(_value)

  return anyStringStartsWith(
    [
      toFormatted({ value: _date, format: 'Mdyy' }),
      toFormatted({ value: _date, format: 'M/d/yy' }),
      toFormatted({ value: _date, format: 'Mdyyyy' }),
      toFormatted({ value: _date, format: DATE_FORMAT_MED }),
      toFormatted({ value: _date, format: DATE_FORMAT_LONG }),
      toFormatted({ value: _date, format: DATE_FORMAT_TRACKING }),
      toFormatted({ value: _date, format: 'ddMMyyyy' })
    ],
    comparison
  )
}

const prepareFetchResponse = (response) => (
  response.text().then((body) => {
    let json = {}

    if (body !== '') {
      json = JSON.parse(body)
    }

    if (!response.ok) {
      const error = JSON.parse(body)
      json = { errors: [error] }
    }

    return {
      status: response.status,
      ok: response.ok,
      json,
    }
  })
)

const priceStartsWithAny = (values, comparison) => (
  anyStringStartsWith(
    ensureArray(values).map((value) => ensureString(value).replace(/[^_-\d]/g, '')),
    ensureString(comparison).replace(/[^_-\d]/g, '')
  )
)

export const groupArray = (data, split = 2) => (
  ensureArray(data).reduce((_grouped, _value, index, sourceArray) => (
    (index % split === 0)
      ? [..._grouped, sourceArray.slice(index, index + split)]
      : _grouped
  ), [])
)

export const intersectArrays = (_values, comparison) => {
  const values = ensureArray(_values)
  return ensureArray(comparison).filter((value) => values.includes(value))
}

export const arrayIncludesAll = (_values, comparison) => {
  const values = ensureArray(_values)
  return ensureArray(comparison).every((value) => (
    stringNotEmpty(value) && values.includes(value)
  ))
}

export const arraysMatch = (array1, array2) => (
  arrayIncludesAll(array1, array2) && arrayIncludesAll(array2, array1)
)

export const arrayIncludesAny = (values, _comparison) => {
  const comparison = ensureArray(_comparison)
  // TODO: explore .some?
  // eslint-disable-next-line eqeqeq
  return (ensureArray(values).find((value) => comparison.includes(value)) != undefined)
}

export const stringIncludesAny = (values, comparison, bidirectional) => (
  ensureArray(values).some((value) => (
    (
      // eslint-disable-next-line eqeqeq
      (value != undefined) &&
      (typeof value !== 'object') &&
      includes(value, comparison, bidirectional)
    ) || false
  ))
)

export const anyStringStartsWith = (values, _comparison) => {
  const comparison = ensureString(_comparison).toLowerCase()

  return ensureArray(values).some((value) => (
    (
      // eslint-disable-next-line eqeqeq
      (value != undefined) &&
      (typeof value !== 'object') &&
      ensureString(value).toLowerCase().startsWith(comparison)
    ) || false
  ))
}

export const stringStartsWithAny = (_comparison, values) => {
  const comparison = ensureString(_comparison).toLowerCase()

  return (
    ensureArray(values).some((value) => (
      (
        // eslint-disable-next-line eqeqeq
        (value != undefined) &&
        (typeof value !== 'object') &&
        comparison.startsWith(ensureString(value).toLowerCase())
      ) || false
    ))
  )
}

export const stringMatchesAny = (_comparison, values) => {
  const comparison = ensureString(_comparison).toLowerCase()

  return (
    ensureArray(values).some((value) => (
      (
        // eslint-disable-next-line eqeqeq
        (value != undefined) &&
        (typeof value !== 'object') &&
        (comparison === ensureString(value).toLowerCase())
      ) || false
    ))
  )
}

// EXPORTED FUNCTIONS

export const isWhite = (color) => (
  color === 'white' ||
  color === '#fff' ||
  color === '#ffffff' ||
  color === 'rgb(255, 255, 255)'
)

export const formatTimelineEventDate = (date) => ([
  toFormatted({ value: date, format: LOCALE_FORMATS.DATE_MED }),
  toFormatted({ value: date, format: LOCALE_FORMATS.TIME_SIMPLE })
].join(' '))

export const formatTimelineEventName = ({ title, description, eventType }) => {
  const eventName = (eventType === TIMELINE_EVENT_ADVANCED_TYPES.CART_COLLAB)
    ? description
    : title || eventType

  return titleCase(
    ensureString(eventName).replace(/[_-]/g, ' ')
  )
}

export const trimStatusLabel = (status) => (
  unsnakecase(lowercase(status))
    .replace('invoice sent', 'invoiced')
    .replace('completed', 'complete')
    .replace('canceled', 'cancel')
    .replace('cancelled', 'cancel')
    .replace('declined', 'decline')
    .replace('refunded', 'refund')
    .replace('authorized', 'auth')
    .replace('awaiting', 'awt')
    .replace(/(.*)(await)( .+)/, '$1awt$3')
    .replace('payment', 'pmnt')
    .replace('partially', 'ptl')
    .replace('manually', 'manual')
    .replace('manual paid', 'manual')
    .replace(/(.*)(manual)( .+)/, '$1mnl$3')
    .replace(/(.*)(partial)( .+)/, '$1ptl$3')
    .replace(/(.*)(submitted for)( .+)/, '$1pending$3')
    .replace('ptl shipped', 'ptl ship')
    .replace('fulfillment', 'fulfill')
    .replace('cash on delivery', 'COD')
    .replace('settlement', 'stl')
)

export const transformStatusLabel = (_label, { id, provider: _vendor } = {}) => {
  const label = lowercase(_label)
  const abbreviation = trimStatusLabel(label)
  const VENDOR = uppercase(_vendor)

  const colorData = COLORS[VENDOR]?.[label] ??
    COLORS[VENDOR]?.[abbreviation] ??
    COLORS[VENDOR]?.[unsnakecase(label)]

  // HMMM: should this return the original/untouched label/value as well?
  return {
    // eslint-disable-next-line eqeqeq
    ...(id != undefined) && { id: ensureStringOnly(id) },
    ...stringNotEmpty(_vendor) && {
      vendor: _vendor,
      ...colorData,
    },
    abbreviation,
    label,
  }
}

export const replaceIndex = (_array, index, value) => {
  const array = ensureArray(_array)
  return [
    ...array.slice(0, index),
    value,
    ...array.slice(index + 1)
  ]
}

export const replaceStringStart = (string, matchString, replacement = '') => {
  if (!isType(string, 'string') || !isType(matchString, 'string')) {
    return string
  }
  return string.replace(new RegExp(`^${ensureString(matchString)}`), replacement)
}

export const replaceStringEnd = (string, matchString, replacement = '') => {
  if (!isType(string, 'string') || !isType(matchString, 'string')) {
    return string
  }
  return string.replace(new RegExp(`${ensureString(matchString)}$`), replacement)
}

export const encodeHTMLEntities = (string) => (
  encodeHTML(ensureString(string))
)

export const decodeHTMLEntities = (string) => (
  decodeHTML(ensureString(string))
)

export const arraysEqual = (array1, array2) => (
  array1 &&
  array2 &&
  array1.length === array2.length &&
  array1.sort().every((value, index) => value === array2.sort()[index])
)

export const clean = (string) => (string
  ? ensureString(string).replace(/\W/g, '')
  : string
)

export const convertSpaces = (string) => (string
  ? string.replace(/ /g, '\u00a0')
  : string
)

export const deepEqual = (object1, object2) => ((
    object1 &&
    object2 &&
    typeof object1 === 'object' &&
    typeof object2 === 'object'
  )
    ? equals(object1, object2)
    : object1 === object2
)

export const delay = (time) => {
  let timeoutId
  const promise = new Promise((res) => {
    timeoutId = setTimeout(res, time)
  })

  promise.cancel = () => {
    try {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    // eslint-disable-next-line no-empty
    } catch {}
  }

  return promise
}

// TODO: use lowecase()
export const stringStartsWith = (value, comparisons = []) => {
  const string = ensureString(value).toLowerCase()
  return ensureArray(comparisons)
    .some((comparison) => (comparison && typeof comparison !== 'object' && string.startsWith(ensureString(comparison).toLowerCase())) || false)
}

// TODO: use lowecase()
export const stringEndsWith = (value, comparisons = []) => {
  const string = ensureString(value).toLowerCase()

  return ensureArray(comparisons)
    .some((comparison) => (comparison && typeof comparison !== 'object' && string.endsWith(ensureString(comparison).toLowerCase())) || false)
}

/**
 * Helper to escape unsafe characters in HTML, including &, <, >, ", ', `, =
 * @param {String} str String to be escaped
 * @return {String} escaped string
 */
 // TODO explore using decodeHTMLEntities
export const escapeSpecialChars = (str) => {
  if (typeof str !== 'string') {
    throw new TypeError(
      'escapeSpecialChars function expects input in type String'
    )
  }

  const escaped = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#x27;',
    '`': '&#x60;',
    '=': '&#x3D;',
  }

  return str.replace(/[&<>"'`=]/g, (m) => (
    escaped[m]
  ))
}

export const checkCancelled = (status) => (
  ['cancelled', 'canceled'].includes(lowercase(status))
)

export const getHash = (unhashed) => (
  clean(unhashed)
)

export const getPagedData = (
  data,
  selectedPage,
  pageSize,
  sortComparator,
  sortField,
  sortDirection
  // eslint-disable-next-line max-params
) => {
  const sortedData = sortField && sortDirection
    ? data.sort((a, b) => {
        const aValue = traverse(sortField, a)
        const bValue = traverse(sortField, b)
        const sortComparison = sortComparator(aValue, bValue)
        return sortDirection === 'asc' ? sortComparison : -sortComparison
      })
    : data
  return sortedData.slice((selectedPage - 1) * pageSize, selectedPage * pageSize)
}

export const handleFetchResponse = (response) => (
  prepareFetchResponse(response).then((responseObj) => {
    if (responseObj.ok) {
      return responseObj.json
    }

    let errorMsg = responseObj

    if (typeof responseObj === 'object') {
      errorMsg = JSON.stringify(responseObj)
    }

    return Promise.reject(safeParse(errorMsg))
  })
)

export const hashCode = (object) => {
  const key = object ? JSON.stringify(object) : null
  return key
    // eslint-disable-next-line no-bitwise
    ? Array.from(key).reduce((s, c) => (Math.imul(31, s) + c.charCodeAt(0)) | 0, 0)
    : null
}

export const includes = (original, comparison, bidirectional) => {
  const formattedOriginal = ensureString(original)
    .replace(/ /g, '')
    .toLowerCase()
  const formattedComparison = ensureString(comparison)
    .replace(/ /g, '')
    .toLowerCase()

  // eslint-disable-next-line max-len
  return stringNotEmpty(formattedComparison) && (formattedOriginal.includes(formattedComparison) || (bidirectional && formattedComparison.includes(formattedOriginal)))
}

// HMMM: not sure if this should use isNumericNegatable or isNumericOnly
export const isNumeric = (string) => (
  // eslint-disable-next-line no-restricted-globals
  isNumericNegatable(string) || (stringNotEmpty(string) && !isNaN(parseFloat(string)) && isFinite(string))
)

export const isAlpha = (value) => (
  isString(value) && /^[a-zA-Z]*$/.test(value)
)

export const isGUID = (value) => (
  isString(value) && /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(value)
)

export const removeEmptyObjects = (array) => (
  ensureArray(array).filter((value) => !isTypeEnhanced(value, 'object') || objectNotEmpty(value))
)

export const combineArrays = (arrays, callback = (array) => array) => (
  ensureArray(arrays).flatMap(callback)
)

export const cartesian = (arrays) => (
  ensureArray(arrays).reduce((_arrays, array) => (
    combineArrays(_arrays, (a) => (
      array.map((b) => (
        [a, b].flat()
      ))
    ))
  ), [[]])
)

export const transpose = (arrays, groupBy, include = () => true) => (
  ensureArray(arrays).reduce((grouped, current) => {
    const groupValue = current?.[groupBy]
    const { [groupValue]: existingGroup = [] } = ensureObject(grouped)

    return {
      ...grouped,
      ...include(groupValue) && {
        [groupValue]: [...existingGroup, current],
      },
    }
  }, {})
)

// NOTE: duplicated items across arrays will prioritize the item in the second array, but only limited by the items in first array
export const unionArraysByKey = (ary1, ary2, key) => (
  ensureArray(ary1).map((item1) => {
    const matchingItem = ensureArray(ary2).find((item2) => (
      // eslint-disable-next-line eqeqeq
      (item2?.[key] != undefined) && (item2[key] === item1?.[key])
    ))
    return {
      ...item1,
      ...matchingItem,
    }
  })
)

// NOTE: duplicated items across arrays will prioritize the item in the second array
export const mergeArraysByKey = (ary1, ary2, key) => (
  [...ensureArray(ary2), ...ensureArray(ary1)].reduce((merged, item) => {
    const { [key]: itemKey } = ensureObject(item)
    if (merged.some(({ [key]: existingItemKey }) => (
      itemKey === existingItemKey
    ))) {
      return merged
    }

    return [
      ...merged,
      item
    ]
  }, [])
)

export const splitAt = (splittable = '', index = 0) => (
  [splittable.slice(0, index), splittable.slice(index)]
)

export const objectMatchers = {
  stringIncludesAny,
  stringStartsWithAny: anyStringStartsWith,
  priceStartsWithAny,
  dateStartsWithAny,
}

export const objectMatchesFilter = (
  object = {},
  matchers = {},
  filter = ''
) => (
  Object.entries(matchers).some(
    ([fieldKey, match = stringIncludesAny]) => {
      const value = traverse(fieldKey, object)
      return match(value, filter)
    }
  )
)

export const objectToFormData = (object, formData = [], previousKey = '') => {
  // eslint-disable-next-line no-param-reassign
  object = safeParse(object)
  if (object && typeof object === 'object') {
    Object.keys(object).forEach((key) => {
      objectToFormData(
        object[key],
        formData,
        previousKey === '' ? key : `${previousKey}[${key}]`
      )
    })
  } else if (previousKey !== '') {
    formData.push(`${encodeURIComponent(previousKey)}=${encodeURIComponent(object)}`)
  }

  return formData.join('&')
}

// Ex. arrayableEntry = [foo, ['1', '2', '3']]
// NOTE: this hanldes 4 most common array formats with https://www.npmjs.com/package/query-string
const arrayableEntryToQuerystring = (arrayableEntry, _options) => {
  const { encodeKey, pruneEmpty = true, arrayFormat = 'comma' } = ensureObject(_options)

  const [paramKey, paramValues] = ensureArray(arrayableEntry)
  const key = !encodeKey ? paramKey : encodeURIComponent(paramKey)

  const _values = ensureArray(paramValues)
  const values = !pruneEmpty
    ? _values
    : _values.reduce((_prunedValues, value) => ([
      ..._prunedValues,
      ...(value === false || stringNotEmpty(value)) ? [value] : []
    ]), [])

  switch (arrayFormat) {
    // 'foo=1&foo=2&foo=3'
    case 'none': {
      return values.map((value) => [key, encodeURIComponent(value)].join('=')).join('&')
    }

    // 'foo[]=1&foo[]=2&foo[]=3'
    case 'bracket': {
      return values.map((value) => [`${key}[]`, encodeURIComponent(value)].join('=')).join('&')
    }

    // 'foo[0]=1&foo[1]=2&foo[3]=3'
    case 'index': {
      return values.map((value, index) => [`${key}[${index}]`, encodeURIComponent(value)].join('=')).join('&')
    }

    // 'foo=1,2,3'
    default: {
      return [key, encodeURIComponent(values.join(','))].join('=')
    }
  }
}

export const objectToQuerystringEnhanced = (_object, options) => {
  const { encodeKey, pruneEmpty } = ensureObject(options)
  // TODO/HMMMM: should this do a cleanObject w/ stringNotEmptyOnly??
  const object = pruneEmpty ? cleanObject(_object) : ensureObject(_object)

  return Object.entries(object).reduce((
    _querystringParts,
    [paramKey, paramValue]
  ) => {
    if (stringEmpty(paramKey)) {
      return _querystringParts
    }

    let value = paramValue
    const key = !encodeKey ? paramKey : encodeURIComponent(paramKey)

    if (Array.isArray(value)) {
      return [
        ..._querystringParts,
        arrayableEntryToQuerystring([key, paramValue], options)
      ]
    }

    if (isType(paramValue, 'object')) {
      // NOTE: think this nesting needs encodeKey: true
      value = objectToQuerystringEnhanced(paramValue, { ...options, encodeKey: true })
    }

    return [
      ..._querystringParts,
      [key, encodeURIComponent(value)].join('=')
    ]
  }, []).join('&')
}

export const objectToQuerystring = (parseable, { enhanced, ...options } = {}) => {
  const object = safeParse(parseable)

  return !enhanced
    // NOTE: this does not properly handle all array formats per https://www.npmjs.com/package/query-string
    ? new URLSearchParams(object).toString()
    : objectToQuerystringEnhanced(object, options)
}

export const prepareRegex = (string) => (string
  // TODO!!: test out with: .replace(/[-[\]{}()*+!<=:?./\\^$|#\s,]/g, '\\$&')
  // TODO!!: test out with: .replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
  // eslint-disable-next-line no-useless-escape
  .replace(/[-[\]{}()*+!<=:?.\/\\^$|#\s,]/g, '\\$&')
  .replace('\\*', '.+') // TODO!!!! revisit as not sure this is correct
)

// Based on lodash function w/ same name
export const escapeRegExp = (_string) => {
  const reHasRegExpChar = RegExp(REGEXP_ESCAPECHARS.source)
  const string = ensureString(_string)
  return (_string && reHasRegExpChar.test(string))
    ? string.replace(REGEXP_ESCAPECHARS, '\\$&')
    : string
}

export const toRegexArray = (csv) => ((csv || '')
  .replace(/, /g, ',')
  .split(',')
  .map((value) => new RegExp(`^${prepareRegex(value)}$`))
)

export const querystringToParams = (queryString) => {
  try {
    const urlParams = new URLSearchParams(ensureString(queryString))
    return [Object.fromEntries(urlParams), urlParams]
  } catch (_ignore) {
    return []
  }
}

export const querystringToObject = (queryString) => (
  querystringToParams(queryString)[0]
)

export const reverseTruncate = (_value, length, prefix = ELLIPSE) => {
  const value = ensureString(_value)
  const truncated = value.slice(value.length - length)

  return value.length <= length
    ? value
    : `${prefix}${truncated}`
}

export const truncate = (_value, _length, prefix = '...') => {
  const value = ensureString(_value)
  const length = _length || value.length
  const truncated = value.slice(0, length)

  return value.length <= length
    ? value
    : `${truncated}${prefix}`
}

export const safeTrimDoubleQuotes = (string) => (
  ensureString(string).replace(/^"(.+(?="$))"$/, '$1')
)

export const safeStringify = (value, ...params) => {
  if (isType(value, 'object')) {
    try {
      // eslint-disable-next-line no-param-reassign
      value = JSON.stringify(value, ...params)
    } catch (ignore) {
      console.debug('Ignoring error stringifying value', ignore)
      return undefined
    }
  }

  return value
}

export const safeDivide = (dividend, divisor, zeroFallback = dividend) => {
  // eslint-disable-next-line eqeqeq
  if ((dividend == undefined) || (divisor == undefined) || (divisor === 0)) {
    return zeroFallback
  }

  return dividend / divisor
}

export const splitName = (fullName) => {
  if (stringEmpty(fullName)) {
    return {}
  }

  const parts = fullName.split(' ')
  const firstName = parts.slice(0, -1).join(' ')
  const lastName = parts.slice(-1)[0]
  return { firstName, lastName }
}

export const ensureName = (nameObject, separator = ' ') => {
  const { firstName, lastName } = ensureObject(nameObject)
  return [firstName, lastName].reduce((name, field) => ([
    ...name,
    ...stringNotEmpty(field) ? [field] : []
  ]), []).join(separator)
}

export const joinValues = (array, separator = '-') => (
  ensureArray(array).reduce((_values, value) => ([
    ..._values,
    ...stringNotEmpty(value) ? [value] : []
  ]), []).join(separator)
)

/**
 * Helper to render a dataset using the same template function
 * @param {Array} set dataset
 * @param {Function} getTemplate function to generate template
 * @param {String} initialValue any template string prepended
 * @return {String} final template
 */
export const templatingLoop = (set, getTemplate, initialValue = '') => (
  set.reduce((accumulator, item, index) => `${accumulator}${getTemplate(item, index)}`, initialValue)
)

// eslint-disable-next-line no-restricted-globals, no-undef
export const traverse = (selector, obj = self, separator = '.') => {
  const properties = Array.isArray(selector)
    ? selector
    : ensureString(selector).split(separator)
  return properties.reduce((prev, curr) => prev && prev[curr], obj)
}

export const isMajorVersion = (version) => {
  if (stringEmpty(version)) {
    return false
  }

  const { major, minor, patch } = semverParse(version) ?? {}

  // eslint-disable-next-line eqeqeq
  return (major != undefined) && !minor && !patch
}

// TODO: consider moving to utils-core (along w above)?
export const compareVersion = (version1, version2) => (
  // eslint-disable-next-line no-nested-ternary
  version1 === version2 ? 0 : semverGT(version1, version2) ? -1 : 1
)

// TODO: make use of this in the app!! (DataItem)??
export const formatNumber = (number, { locale = DEFAULT_LOCALE, ...options }) => (
  Number(number).toLocaleString(locale, options)
)

export const formatDate = (value, { format = LOCALE_FORMATS.DATETIME_MED, ...options } = {}) => (
  value
    ? toFormatted({ value, format, ...options })
    : undefined
)

export const getOrigin = (origin, referer) => {
  const subOrigin = referer ? referer.match(/\?origin=([^?&]+)/) : null
  if (subOrigin) {
    // eslint-disable-next-line no-param-reassign
    origin = decodeURIComponent(subOrigin[1])
  }

  return origin || referer
}

export const handleError = (response) => (
  new Promise((resolve, reject) => {
    if (response && response.error) {
      console.error(
        `handleError, response.error: ${JSON.stringify(response.error)}`
      )
      reject(response.error)
    } else {
      resolve(response)
    }
  })
)

// TODO consolidate DATA_ITEM_TYPES/getDataType (ex BC transformOrderDisplayType)
export const getDataType = (_value, attributes, defaultType = DATA_ITEM_TYPES.TEXT) => {
  const value = lowercase(_value)

  const { identifier: _identifier } = ensureObject(attributes)
  const identifier = lowercase(_identifier)

  switch (true) {
    case ((identifier === 'rating') && isNumericOnly(value)): {
      return DATA_ITEM_TYPES.RATING
    }

    case ['id', 'status', 'male', 'female', 'yes', 'no', 'true', 'false', 'sentiment'].includes(identifier):
    case (identifier?.endsWith('sentiment') && ['positive', 'neutral', 'negative'].includes(value)):
    case (identifier?.endsWith('state') && ['enabled', 'disabled'].includes(value)):
    case identifier?.endsWith('status'): {
      return DATA_ITEM_TYPES.BADGE
    }

    case identifier?.includes('phone'): {
      return DATA_ITEM_TYPES.PHONE
    }

    case ['email', 'twitter', 'facebook', 'google'].includes(identifier): {
      return DATA_ITEM_TYPES.IDENTIFIER
    }

    case (isTypeEnhanced(_value, 'object') && stringNotEmpty(_value.href) && stringNotEmpty(_value.text)):
    case (value.startsWith('http:') || value.startsWith('https:')): {
      return DATA_ITEM_TYPES.LINK
    }

    case isTypeEnhanced(_value, 'boolean'): {
      return DATA_ITEM_TYPES.BOOLEAN
    }

    case isTypeEnhanced(_value, 'object'):
    case isTypeEnhanced(_value, 'array'):
    case isParseable(_value): {
      return (identifier === 'products') ? DATA_ITEM_TYPES.PRODUCTS : DATA_ITEM_TYPES.JSON
    }

    case isGUID(value):
    case (isNumeric(_value) && (!identifier || !stringIncludesAny([
      'time',
      'date',
      'banned at',
      'paused at',
      'updated at',
      'created at',
      'deleted at',
      'disabled at',
      'enrolled at',
      'subscribed at',
      'unsubscribed at'
    ], identifier, true))):
    case value.includes('::'): {
      return DATA_ITEM_TYPES.PILL
    }

    // NOTE: this will often "fail" (but intentionally doesn't throw) as we send in strings that are not valid as timestamps
    // eslint-disable-next-line eqeqeq
    case (convertDateTime(_value, { shouldThrow: false, shouldLog: false }) != undefined): {
      return DATA_ITEM_TYPES.DATESTAMP
    }

    // TODO: handle/create DATA_ITEM_TYPES.OBJECT or DATA_ITEM_TYPES.TREE??

    default: {
      return defaultType
    }
  }
}

export const prepareReplacements = (object) => (
  Object.entries(cleanObject(object, false, stringEmptyOnly)).reduce((_prepared, [key, value]) => ({
    ..._prepared,
    [ensureLeadingDollar(key)]: value,
  }), {})
)

export const appendQuery = (_basePath, queryString) => {
  const basePath = ensureString(_basePath)
  if (stringEmpty(queryString)) {
    return basePath
  }
  return `${basePath}${basePath.includes('?') ? '&' : '?'}${queryString}`
}

export const appendParams = (basePath, params) => (
  appendQuery(basePath, ensureArray(params).join('&'))
)
