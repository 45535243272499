/* eslint-disable eqeqeq */
import React, { useEffect, useReducer, useContext, createContext } from 'react'

import {
  deepEqual,
  replaceIndex,
  getMetaProviderSet,
} from '@agnostack/lib-utils-js'
import {
  isTrue,
  arrayEmpty,
  objectEmpty,
  arrayNotEmpty,
  objectNotEmpty,
  stringNotEmpty,
  ensureObject,
  ensureArray,
  deepmerge,
} from '@agnostack/lib-core'

export const GLOBAL_STATE_KEYS = {
  ZD_SUBDOMAIN: 'zd_subdomain',
}

export const GLOBAL_DISPATCH_TYPES = {
  SET: 'SET',
  AUTH_STATE: 'authState',
}

export const DISPATCH = {
  ...GLOBAL_DISPATCH_TYPES,
  SEARCH_STATE: {
    SHOW_ADVANCED: 'SEARCH_STATE_SHOW_ADVANCED',
    DATA: 'SEARCH_STATE_DATA',
    CUSTOMERS: 'SEARCH_STATE_CUSTOMERS',
  },
  CUSTOMER_STATE: {
    ACTIVE: 'CUSTOMER_STATE_ACTIVE',
    EXPANDED: 'CUSTOMER_STATE_EXPANDED',
    ADDRESSES: 'CUSTOMER_STATE_ADDRESSES',
    CUSTOMERS: 'CUSTOMER_STATE_CUSTOMERS',
  },
  STEP_STATE: {
    DATA: 'STEP_STATE_DATA',
    STEPS: 'STEP_STATE_STEPS',
  },
  ORDERS_STATE: {
    DATA: 'ORDERS_STATE_DATA',
    PRODUCTS: 'ORDERS_STATE_PRODUCTS',
  },
  REMINDERS_STATE: 'REMINDERS_STATE',
  LOADING_STATE: 'LOADING_STATE',
  TICKETS_STATE: 'TICKETS_STATE',
  MACROS_STATE: 'MACROS_STATE',
  DATA_STATE: 'DATA_STATE',
  SAVED_DATA: 'SAVED_DATA',
  SORT_STATE: 'SORT_STATE',
  APP_LOCATION: 'APP_LOCATION',
  CONFIGURATION_RECEIVED: 'CONFIGURATION_RECEIVED',
}

const getUpdatedData = ({ data: _updates, previousData: _previousData, action }) => {
  if (_updates == undefined) {
    return _previousData
  }

  const previousData = ensureObject(_previousData)
  if (objectEmpty(_updates)) {
    return previousData
  }

  const updates = ensureObject(_updates)
  let updatedData = {}
  switch (action) {
    case 'merge': {
      updatedData = Object.entries(updates).reduce((
        _merged,
        [mergeKey, mergeData]
      ) => ({
        ..._merged,
        [mergeKey]: {
          ...previousData[mergeKey],
          ...mergeData,
        },
      }), previousData)
      break
    }

    case 'deep_merge': {
      updatedData = Object.entries(updates).reduce((
        _merged,
        [mergeKey, mergeData]
      ) => ({
        ..._merged,
        [mergeKey]: Object.entries(mergeData).reduce((
          subMerged,
          [subKey, subData]
        ) => ({
          ...subMerged,
          [subKey]: {
            ...previousData[mergeKey]?.[subKey],
            ...subData,
          },
        }), ensureObject(previousData[mergeKey])),
      }), previousData)
      break
    }

    case 'super_deep_merge': {
      updatedData = Object.entries(updates).reduce((
        _merged,
        [mergeKey, mergeData]
      ) => ({
        ..._merged,
        [mergeKey]: Object.entries(mergeData).reduce((
            subMerged,
            [subKey, subData]
          ) => ({
          ...subMerged,
          [subKey]: Object.entries(subData).reduce((
            subSubMerged,
            [subSubKey, subSubData]
          ) => ({
            ...subSubMerged,
            [subSubKey]: {
              ...previousData[mergeKey]?.[subKey]?.[subSubKey],
              ...subSubData,
            },
          }), ensureObject(previousData[mergeKey]?.[subKey])),
        }), ensureObject(previousData[mergeKey])),
      }), previousData)
      break
    }

    case 'extra_deep_merge': {
      updatedData = deepmerge(previousData, updates)
      break
    }

    default: {
      updatedData = {
        ...previousData,
        ...updates,
      }
    }
  }

  return updatedData
}

// eslint-disable-next-line no-unused-vars
const baseReducer = (state, { type, payload }) => {
  if (!type) {
    throw new Error(`action missing type(${type})`)
  }

  if (type === GLOBAL_DISPATCH_TYPES.SET) {
    return { ...state, ...payload }
  }

  return undefined
}

// TODO: move this out of here (into commerce app)
const statefulReducer = (state, { type, payload }) => {
  switch (type) {
    case DISPATCH.SEARCH_STATE.SHOW_ADVANCED: {
      const { searchState: previousSearchState } = state

      return {
        ...state,
        searchState: {
          ...previousSearchState,
          showAdvanced: payload,
        },
      }
    }

    case DISPATCH.SEARCH_STATE.DATA: {
      const {
        searchState: previousSearchState,
        searchState: {
          data: previousSearchData,
        } = {},
      } = state

      return {
        ...state,
        searchState: {
          ...previousSearchState,
          data: {
            ...objectNotEmpty(payload) && {
              ...previousSearchData,
              ...payload,
            },
          },
        },
      }
    }

    case DISPATCH.SEARCH_STATE.CUSTOMERS: {
      const { searchState: previousSearchState } = state

      return {
        ...state,
        searchState: {
          ...previousSearchState,
          customers: payload,
          orders: null,
        },
      }
    }

    // TODO: implement loadingState ids and useLoadingState hook?
    // cosnt { } = useLoadingState()
    // cosnt { } = useLoadingState(123)
    case DISPATCH.LOADING_STATE: {
      const { loadingState: previousLoadingState } = state
      const { action, ...updates } = payload

      return {
        ...state,
        loadingState: {
          ...(action === 'reset') ? {
            finished: undefined,
            finishedStatus: undefined,
            finishedData: undefined,
            shielded: false,
          } : previousLoadingState,
          ...updates,
        },
      }
    }

    case DISPATCH.APP_LOCATION: {
      const { location, route, params, action } = payload
      const hasRoute = stringNotEmpty(route)
      const {
        activeInstallation: {
          app_product,
          app_locations,
        } = {},
      } = state

      const {
        [app_product]: {
          [location]: {
            route: previousAppLocationRoute,
            params: {
              zat,
              ...previousAppLocationParams
            } = {},
            ...previousAppLocationData
          } = {},
          ...previousOtherAppLocations
        } = {},
        ...previousOtherAppProducts
      } = app_locations ?? {}

      return {
        ...state,
        activeInstallation: {
          ...state?.activeInstallation,
          app_locations: {
            ...previousOtherAppProducts,
            [app_product]: {
              ...previousOtherAppLocations,
              [location]: {
                ...previousAppLocationData,
                ...hasRoute && { route },
                params: {
                  zat,
                  ...(!hasRoute && (previousAppLocationRoute === route) && (action != 'clearParams')) && {
                    ...previousAppLocationParams,
                  },
                  ...params,
                },
              },
            },
          },
        },
      }
    }

    case DISPATCH.CUSTOMER_STATE.EXPANDED: {
      const { customerState: previousCustomerState } = state

      return {
        ...state,
        customerState: {
          ...previousCustomerState,
          expanded: isTrue(payload),
        },
      }
    }

    case DISPATCH.CUSTOMER_STATE.ACTIVE: {
      const { customerState: previousCustomerState } = state

      return {
        ...state,
        customerState: {
          ...previousCustomerState,
          active: payload,
        },
      }
    }

    case DISPATCH.CUSTOMER_STATE.CUSTOMERS: {
      const { customerState: previousCustomerState } = state

      return {
        ...state,
        customerState: {
          ...previousCustomerState,
          customers: payload,
        },
      }
    }

    case DISPATCH.CUSTOMER_STATE.ADDRESSES: {
      const { customerState: previousCustomerState } = state

      return {
        ...state,
        customerState: {
          ...previousCustomerState,
          addresses: payload,
        },
      }
    }

    case DISPATCH.TICKETS_STATE: {
      const { ticketsState: previousTicketsState } = state

      return {
        ...state,
        ticketsState: {
          ...previousTicketsState,
          ...payload,
        },
      }
    }

    case DISPATCH.ORDERS_STATE.PRODUCTS: {
      const {
        ordersState: {
          availableProducts: previousAvailableProducts,
          ...previousOrdersState
        },
      } = state
      const { key, items } = payload

      return {
        ...state,
        ordersState: {
          ...previousOrdersState,
          availableProducts: {
            ...previousAvailableProducts,
            [key]: items,
          },
        },
      }
    }

    case DISPATCH.ORDERS_STATE.DATA: {
      const {
        ordersState: previousOrdersState,
        sortState: previousSortState,
      } = state
      const { orders: previousOrders, selectedOrder: previousSelectedOrder } = previousOrdersState
      const { orders: updatedOrders, selectedOrder } = payload
      const previousOrderIds = ensureArray(previousOrders).map(({ id }) => id).sort()
      const updatedOrderIds = ensureArray(updatedOrders).map(({ id }) => id).sort()

      // NOTE: updatedOrders could be 'null' and in that case we want ordersChanged = true
      const ordersChanged = (
        (updatedOrders !== undefined) &&
        arrayNotEmpty(previousOrderIds) &&
        !deepEqual(previousOrderIds, updatedOrderIds)
      )

      const selectedOrderChanged = (
        (selectedOrder != undefined) &&
        ((previousSelectedOrder == undefined) || !deepEqual(previousSelectedOrder, selectedOrder))
      )

      const orders = ensureArray(updatedOrders || previousOrders)
      const { id: selectedOrderId } = ensureObject(selectedOrder)
      const { providerSetId: selectedProviderSetId } = getMetaProviderSet(selectedOrder)
      const updatedOrderIndex = selectedOrderChanged ? orders.findIndex((order) => {
        const { id } = ensureObject(order)
        const { providerSetId } = getMetaProviderSet(order)

        return (id === selectedOrderId) && (providerSetId === selectedProviderSetId)
      }) : undefined

      return {
        ...state,
        ...ordersChanged && {
          sortState: {
            ...previousSortState,
            selectedPage: 1,
          },
        },
        ordersState: {
          ...previousOrdersState,
          ...payload,
          ...((updatedOrderIndex != undefined) && (updatedOrderIndex >= 0)) && {
            previousOrder: orders[updatedOrderIndex - 1],
            nextOrder: orders[updatedOrderIndex + 1],
            orders: replaceIndex(orders, updatedOrderIndex, selectedOrder),
          },
        },
      }
    }

    case DISPATCH.MACROS_STATE: {
      const { macrosState: previousMacrosState } = state
      // HMMM, what is this destructure of 'macro' here?
      const { instanceId = '', action, macro, ...data } = payload
      const { [instanceId]: previousInstanceState } = previousMacrosState

      const updatedMacrosState = {
        ...previousMacrosState,
        [instanceId]: { ...previousInstanceState, ...data },
      }

      return {
        ...state,
        macrosState: updatedMacrosState,
      }
    }

    case DISPATCH.REMINDERS_STATE: {
      const { remindersState: previousRemindersState } = state
      const { instanceId = '', ...data } = payload
      const { [instanceId]: previousInstanceState } = previousRemindersState

      const updatedRemindersState = {
        ...previousRemindersState,
        [instanceId]: {
          ...previousInstanceState,
          ...data,
        },
      }

      return {
        ...state,
        remindersState: updatedRemindersState,
      }
    }

    case DISPATCH.DATA_STATE: {
      const { dataState: previousDataState } = state
      const { instanceId = '', ...data } = payload
      const { [instanceId]: previousInstanceState } = previousDataState

      const updatedDataState = {
        ...previousDataState,
        [instanceId]: {
          ...previousInstanceState,
          ...data,
        },
      }

      return {
        ...state,
        dataState: updatedDataState,
      }
    }

    // TODO: move into useData???
    case DISPATCH.SAVED_DATA: {
      const { dataState: previousDataState } = state
      const {
        action,
        dataType,
        updates,
        updates: {
          id: recordId,
          ..._updates
        } = {},
        ...updatedData
      } = payload

      let dataTypeUpdates
      if (dataType) {
        const { [dataType]: _previousTypeData } = ensureObject(previousDataState)
        const previousTypeData = ensureObject(_previousTypeData)

        switch (action) {
          case 'added': {
            if (objectNotEmpty(_updates)) {
              dataTypeUpdates = {
                ...previousTypeData,
                [recordId]: _updates,
              }
            }

            break
          }

          case 'modified': {
            dataTypeUpdates = Object.entries(previousTypeData).reduce((
              __updatedSavedData,
              [id, _savedData]
            ) => ({
              ...__updatedSavedData,
              [id]: (id === recordId) ? _updates : _savedData,
            }), {})

            break
          }

          case 'removed': {
            dataTypeUpdates = Object.entries(previousTypeData).reduce((
              __updatedSavedData,
              [id, _savedData]
            ) => ({
              ...__updatedSavedData,
              ...(id !== recordId) && {
                [id]: _savedData,
              },
            }), {})

            break
          }

          default: {
            dataTypeUpdates = ensureObject(updates)
            break
          }
        }
      }

      return {
        ...state,
        dataState: {
          ...previousDataState,
          ...updatedData,
          ...(dataTypeUpdates) && {
            [dataType]: dataTypeUpdates,
          },
        },
      }
    }

    case DISPATCH.SORT_STATE: {
      const { sortState: previousSortState } = state

      return {
        ...state,
        sortState: {
          ...previousSortState,
          ...payload,
        },
      }
    }

    case DISPATCH.STEP_STATE.STEPS: {
      const {
        key,
        action,
        data: updatedData,
        data: {
          id,
          hidden,
          status,
          selected,
        } = {},
      } = payload

      const {
        [key]: {
          current: _previousCurrent,
          ...previousStepsData // NOTE: this contains initial steps data
        } = {},
      } = state

      const previousCurrent = ensureArray(_previousCurrent)

      let updatedCurrentData
      switch (true) {
        case (action === 'initialize'): {
          return {
            ...state,
            [key]: {
              initial: updatedData,
              current: updatedData,
            },
          }
        }

        // NOTE: this is a call to set selected by id
        case ((id != undefined) && (selected != undefined)): {
          updatedCurrentData = previousCurrent.map((step) => {
            const { id: stepId } = ensureObject(step)
            return {
              ...step,
              selected: stepId === id ? selected : !selected,
            }
          })

          break
        }

        // NOTE: this is a call to set hidden by id
        case ((id != undefined) && ((hidden != undefined) || (status != undefined))): {
          const {
            hidden: _hidden,
            status: _status,
          } = ensureObject(previousCurrent.find(({ id: _id }) => _id === id))
          const isModified = (hidden !== _hidden || status !== _status)

          if (!isModified) {
            return state
          }

          updatedCurrentData = previousCurrent.map((step) => {
            const { id: stepId } = ensureObject(step)
            return stepId !== id ? step : {
              ...step,
              ...hidden != undefined && {
                hidden,
              },
              ...status != undefined && {
                status,
              },
              ...selected != undefined && {
                selected,
              },
            }
          })

          break
        }

        case objectNotEmpty(updatedData): {
          updatedCurrentData = previousCurrent.map((previous) => {
            const { id: _id } = previous
            return (_id != id)
              ? previous
              : {
                ...previous,
                ...updatedData,
              }
          })

          break
        }

        default: {
          break
        }
      }

      // NOTE this is the pass through of all updates above by case
      return {
        ...state,
        ...(objectNotEmpty(updatedCurrentData) && !deepEqual(updatedCurrentData, previousCurrent)) && {
          [key]: {
            ...previousStepsData,
            current: updatedCurrentData,
          },
        },
      }
    }

    case DISPATCH.STEP_STATE.DATA: {
      const { key, action, data: _data, actionable } = payload
      const { [key]: _previousData } = state
      const previousData = ensureObject(_previousData)

      const data = Object.entries(ensureObject(actionable)).reduce((__previousData, [_action, _actionableData]) => (
        getUpdatedData({ data: _actionableData, previousData: __previousData, action: _action })
      ), getUpdatedData({ data: _data, previousData, action }))

      return {
        ...state,
        [key]: data,
      }
    }

    case DISPATCH.CONFIGURATION_RECEIVED: {
      const {
        sortState,
        searchState,
        ordersState,
        activeInstallation,
      } = state

      const {
        onChange = () => {},
        updatedSettings,
        savedProviderSets,
        updatedProviderSets,
        updatedProviderSetsMeta,
        ..._updates
      } = payload

      const providerSetsChanged = (objectNotEmpty(savedProviderSets) && !deepEqual(savedProviderSets, updatedProviderSets))

      if (providerSetsChanged) {
        onChange()
      }

      return {
        ...state,
        isConfiguring: false,
        modifiedSubscriptionBillingData: null, // TODO: explore null vs undefined
        providerSetsFeatureFlags: undefined,
        savedProviderSets,
        // NOTE: confirm if we need to trigger anything here in addition/instead?
        ...providerSetsChanged && {
          customerState: {
            active: {
              activeId: null, // TODO: explore null vs undefined
              activeEmail: null, // TODO: explore null vs undefined
              activeType: null, // TODO: explore null vs undefined
              customer: null, // TODO: explore null vs undefined
            },
            addresses: null, // TODO: explore null vs undefined
            customers: null, // TODO: explore null vs undefined
            expanded: false,
          },
          searchState: {
            ...searchState,
            customers: null, // TODO: explore null vs undefined
            orders: null, // TODO: explore null vs undefined
            // data: null, // TODO: explore null vs undefined // HMMMM: not sure why we don't clear these out?
            // showAdvanced: false, // HMMMM: not sure why we don't clear these out?
          },
          sortState: {
            ...sortState,
            selectedPage: 1,
          },
          ordersState: {
            ...ordersState,
            orders: null, // TODO: explore null vs undefined
            selectedOrder: null, // TODO: explore null vs undefined
            availableProducts: {},
            // pinnedOrderId: null, // TODO: explore null vs undefined // HMMMM: not sure why we don't clear these out?
          },
          remindersState: {
            reminders: null, // TODO: explore null vs undefined // HMMM: added clear of reminders
          },
          macrosState: {}, // HMMM: added clear of macros
          // dataState: {}, // HMMM: should we clear out dataState? (or any portion of it)
        },
        ...objectNotEmpty(updatedSettings) && {
          activeInstallation: {
            ...activeInstallation,
            // TODO!!!: investigate moving settings outside of activeInstallation
            settings: {
              ...activeInstallation?.settings,
              ...updatedSettings,
            },
          },
        },
        ...objectNotEmpty(updatedProviderSetsMeta) && {
          savedProviderSetsMeta: updatedProviderSetsMeta,
        },
        ..._updates,
      }
    }

    default: {
      return undefined
    }
  }
}

export const StatefulContext = createContext()

export const StatefulProvider = (props, callbacks) => {
  const { contextProvider: ContextProvider = StatefulContext.Provider, value, children } = ensureObject(props)
  const { onDispatch, onUpdate, transformer, reducer: _reducer, reducers: _reducers } = ensureObject(callbacks)

  const [contextState, _dispatch] = useReducer((state, data) => {
    const reducers = [
      baseReducer,
      ...ensureArray(_reducers ?? _reducer ?? statefulReducer)
    ]
    const { updatedContextState, matchedReducers } = reducers.reduce((
      previousNested,
      chainedReducer
    ) => {
      const chainedReduce = chainedReducer(previousNested.updatedContextState, data)

      if (chainedReduce == undefined) {
        return previousNested
      }

      return {
        updatedContextState: chainedReduce,
        matchedReducers: [
          ...previousNested.matchedReducers,
          chainedReducer
        ],
      }
    }, { updatedContextState: state, matchedReducers: [] })

    if (arrayEmpty(matchedReducers)) {
      throw new Error(`action.type[${data.type}] not mapped by reducer`)
    }

    return transformer?.(updatedContextState) ?? updatedContextState
  }, ensureObject(value))

  useEffect(() => {
    if (onUpdate) {
      onUpdate(contextState)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextState])

  const dispatch = (..._args) => {
    _dispatch(..._args)
    if (onDispatch) {
      onDispatch(..._args)
    }
  }

  return (
    <ContextProvider value={[contextState, dispatch]}>
      {children}
    </ContextProvider>
  )
}

export const ReducerProvider = ({ reducer, reducers, transformer, onDispatch, onUpdate, ...props }) => (
  StatefulProvider(props, { reducer, reducers, transformer, onDispatch, onUpdate })
)

export const useContextState = (statefulContext) => {
  const [contextState, dispatch] = useContext(statefulContext ?? StatefulContext) ?? []

  const setContextState = (payload) => {
    dispatch({ type: GLOBAL_DISPATCH_TYPES.SET, payload })
  }

  return [contextState, dispatch, setContextState]
}
