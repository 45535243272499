import React from 'react'

import { useContextGlobal, GLOBAL_ACTIONS } from '../../../util'
import { StyledSearchBox } from './SearchNav.style'

const SearchNav = (renderProps) => {
  const [{ showSearch }, dispatch] = useContextGlobal()

  const onShowSearch = (value) => {
    dispatch({
      type: GLOBAL_ACTIONS.SET,
      payload: { showSearch: value },
    })
  }

  return (
    <StyledSearchBox
      showSearch={showSearch}
      onShowSearch={onShowSearch}
      {...renderProps}
    />
  )
}

export default SearchNav
