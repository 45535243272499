import { useRef, useEffect } from 'react'

import { deepEqual } from '@agnostack/lib-utils-js'
import { isString, isTypeEnhanced, stringNotEmpty } from '@agnostack/lib-core'

export const usePrevious = (value, initialValue, ensureValue = false) => {
  const ref = useRef(initialValue)

  useEffect(() => {
    switch (true) {
      case !ensureValue: {
        ref.current = value
        break
      }

      case isString(value): {
        if (stringNotEmpty(value) || stringNotEmpty(initialValue)) {
          ref.current = value
        }
        break
      }

      case (isTypeEnhanced(value, 'object') && !deepEqual(value, ref.current)): {
        ref.current = value
        break
      }

      default: {
        break
      }
    }
  }, [value])

  return ref?.current
}

// NOTE: this only stores things that have a value (ie. if something is toggled 'foo'>undefined>'bar', it will remember 'foo' when bar is set)
export const usePreviousValue = (value, initialValue) => (
  usePrevious(value, initialValue, true)
)
