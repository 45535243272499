import styled from 'styled-components'
import hexToRgba from 'hex-to-rgba'

// TODO: remove garden as a dependency in this project
import { Button } from '@zendeskgarden/react-buttons'
import { Notification as _Notification } from '@zendeskgarden/react-notifications'

import { DefaultToastContainer } from 'react-toast-notifications'

import { locations } from '@agnostack/lib-core'
import { SVGIcon } from '@agnostack/lib-utils-react'

const TRANSFORM_MAP = {
  right: 'translate3d(120%, 0, 0)',
  left: 'translate3d(-120%, 0, 0)',
  bottom: 'translate3d(0, 120%, 0)',
  top: 'translate3d(0, -120%, 0)',
}

const getTransform = (placement) => {
  const pos = placement.split('-')
  const relevantPlacement = pos[1] === 'center' ? pos[0] : pos[1]
  return TRANSFORM_MAP[relevantPlacement]
}

const transitionStates = (placement) => ({
  entering: { transform: getTransform(placement) },
  entered: { transform: 'translate3d(0,0,0)' },
  exiting: { transform: 'scale(0.66)', opacity: 0 },
  exited: { transform: 'scale(0.66)', opacity: 0 },
})

export const NotificationsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`

export const NotificationsWrapper = styled.div`
  position: fixed;
  z-index: +50;
`

export const NotificationWrapper = styled.div`
  ${({ theme: { borders, space, colors, palette } = {} }) => `
    display: flex;
    width: 100%;
    border-radius: 0.5rem;
    background-color: ${colors?.background};
    border: ${borders.sm} ${palette?.[colors?.neutralHue]?.[300]};
    box-shadow: 0 3px ${space?.xs} ${hexToRgba(palette?.black, 0.175)};
  `}
`

export const TitleSection = styled.div`
  ${({ theme: { fontSizes } = {} }) => `
    display: flex;
    flex: 1 1 0%;
    align-items: flex-start;
    overflow: hidden;
    padding: ${fontSizes?.xs} ${fontSizes?.sm};
  `}
`

export const TitleContainer = styled.div`
  width: 100%;
  margin: 0;
  margin-top: 0.25rem;
`

export const TitleWrapper = styled.div`
  ${({ theme: { colors, palette, fontWeights } = {} }) => `
    color: ${palette?.[colors?.neutralHue]?.[800]};
    font-weight: ${fontWeights?.medium};
  `}
`

export const ChildrenWrapper = styled.div`
  ${({ theme: { colors, palette } = {} }) => `
    color: ${palette?.[colors?.neutralHue]?.[600]};
  `}
`

export const TitleOrChildrenWrapper = styled.div`
  ${({ theme: { colors, palette } = {} }) => `
    color: ${palette?.[colors?.neutralHue]?.[600]};
  `}
`

export const ButtonSection = styled.div`
  ${({ theme: { colors, palette } = {} }) => `
    display: flex;
    border-left: 1px solid ${palette?.[colors?.neutralHue]?.[200]};
  `}
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ActionButton = styled(Button)`
  ${({ actionType, theme: { borders, space, colors, palette, fontSizes } = {} }) => `
    min-width: 66px;
    width: 100%;
    margin: 0;
    border: 0;
    border-radius: unset;
    line-height: unset;
    font-weight: 700;
    align-items: flex-start;
    font-size: 11px;
    padding: ${fontSizes?.xs} ${space?.xs};

    &:not(:last-child) {
      border-bottom: ${borders?.sm} ${palette?.[colors?.neutralHue]?.[200]};
    }

    &:first-of-type {
      border-top-right-radius: 0.5rem;
    }

    &:last-of-type {
      border-bottom-right-radius: 0.5rem;
      flex-grow: 1;
    }

    ${(actionType === 'secondary') ?
      `
        color: ${palette?.[colors?.neutralHue]?.[700]};
        &:hover {
          color: ${palette?.[colors?.neutralHue]?.[500]};
        }
    ` : `
        color: ${palette?.[colors?.primaryHue]?.[600]};
        &:hover {
          color: ${palette?.[colors?.primaryHue]?.[400]};
        }
    `}
  `}
`

export const ButtonBody = styled.div`
  display: flex;
  align-items: center;
`

export const IconWrapper = styled(SVGIcon).attrs(() => ({
  size: 'xsmall',
  display: 'inline-flex',
}))`
  margin-right: 2px;
`

export const ToastWrapper = styled.div`
  ${({ 'data-height': height = 'auto' }) => `height: ${height};`}
  ${({ transitionDuration }) => transitionDuration && `
    transition: height ${transitionDuration - 100}ms 100ms;
  `}
`

// TODO!!!!!: somehow pass location in here to ensure minWidth is dynamic
export const ToastChild = styled.div`
  ${({ location, theme: { space: { base = 4, xxs } = {} } = {} }) => {
    // NOTE: the space.base * 2 here is to account for the shadow
    const minWidth = (locations.MIN_WIDTHS[location] ?? locations.MIN_WIDTHS.DEFAULT) - (base * 2)

    return `
      display: flex;
      flex-direction: column;
      width: 100%;
      ${minWidth ? `min-width: ${minWidth}px;` : ''}
      ${xxs ? `margin-bottom: ${xxs};` : ''}
      ${({ transitionDuration }) => `
        transition: transform ${transitionDuration}ms cubic-bezier(0.2, 0, 0, 1), opacity ${transitionDuration}ms;
      `}
      ${({ placement, transitionState }) => transitionStates(placement)[transitionState]};
    `
  }}
`

export const StyledToastContainer = styled(DefaultToastContainer)`
  ${({ theme: { space } = {} }) => `
    && {
      padding: 0 0 ${space?.sm} ${space?.sm};
    }
  `}
`

export const StyledNotification = styled(_Notification)`
  ${({ theme: { space, palette, fontSizes } = {} }) => `
    padding-top: ${fontSizes?.xs};
    padding-bottom: ${fontSizes?.xs};
    box-shadow: 0 3px ${space?.xs} ${hexToRgba(palette?.black, 0.175)};
  `}
`
