import { useLocation } from '@reach/router'

export const useQueryParam = (paramName) => {
  const location = useLocation()

  // eslint-disable-next-line eqeqeq
  if ((paramName == undefined) || !location?.search) {
    return undefined
  }

  let search
  try {
    // NOTE: IE not supported
    search = new URLSearchParams(location.search)
  } catch (ignore) {
    console.warn('Ignoring error getting search params', ignore)
  }

  return search?.get?.(paramName)
}
