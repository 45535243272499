import styled from 'styled-components'

// TODO: rename this to IconSizes
export const SVGIconSizes = {
  xxsmall: 8,
  xsmall: 10,
  small: 12,
  medium: 14,
  regular: 16,
  large: 24,
  xlarge: 42,
  xxlarge: 80,
}

// TODO: rename this to IconSizeWrapper
export const SVGIcon = styled.span`
  display: ${({ display }) => (display || 'inline-block')};
  ${({ size }) => SVGIconSizes[size] && `
    width: ${SVGIconSizes[size]}px;
    height: ${SVGIconSizes[size]}px;
  `}
`

export const ImagePlaceholder = styled.span`
  ${({ size = 'xsmall', width = size, height = size }) => `
    min-width: ${SVGIconSizes[width]}px;
    min-height: ${SVGIconSizes[height]}px;
  `}
`
