import React from 'react'
import { navigate } from 'gatsby'

import {
  snakecase,
  toFormatted,
  getDateTimeFromString,
  removeLeadingTrailingSlash,
  DATE_FORMAT_MED,
} from '@agnostack/lib-core'

import {
  Wrapper,
  Screenshot,
  ScreenshotDate,
  ScreenshotTitle,
  ScreenshotImage,
} from './ScreenshotButton.style'

const ScreenshotButton = ({ title, path, date }) => {
  const screenShot = snakecase(removeLeadingTrailingSlash(path))
  const timestamp = getDateTimeFromString({ value: date, format: DATE_FORMAT_MED })

  if (!path) {
    return null
  }

  return (
    <Screenshot title={title} onClick={() => navigate(path)}>
      <Wrapper>
        <ScreenshotTitle>{title}</ScreenshotTitle>
        {timestamp && <ScreenshotDate>{toFormatted({ value: timestamp, format: 'MMM d, yyyy' })}</ScreenshotDate>}
      </Wrapper>
      <Wrapper>
        <ScreenshotImage
          alt={title}
          src={`/images/screenshots/${screenShot}.png`}
        />
      </Wrapper>
    </Screenshot>
  )
}

export default ScreenshotButton
