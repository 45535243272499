/* eslint-disable no-underscore-dangle */
import React from 'react'
import { globalHistory } from '@reach/router'
import { withTheme } from 'styled-components'
import { ensureObject } from '@agnostack/lib-core'
import { CloseIcon } from '@agnostack/components-assets'

import { useDemo } from '../../../hooks'

import {
  Wrapper,
  Container,
  BodyWrapper,
  IconWrapper,
  CalloutTitle,
  CTATitle,
  CTAWrapper,
  CTAButton,
} from './Tophat.style'

const Tophat = ({ theme, format = 'dark' }) => {
  const {
    location: {
      pathname,
    } = {},
  } = ensureObject(globalHistory)

  const {
    modal,
    isSmall,
    isOpen,
    title,
    ctaTitle,
    ctaChildren,
    demoBooked,
    closeModal,
    openModal,
  } = useDemo({ pathname, breakpoints: theme?.breakpoints })

  return (
    <>
      <Wrapper open={isOpen}>
        <Container format={format}>
          <BodyWrapper>
            <CalloutTitle>{title}</CalloutTitle>
            <CTAWrapper>
              <CTATitle>{ctaTitle}</CTATitle>
              <CTAButton
                format={format}
                highlight={demoBooked}
                onClick={openModal}
              >
                {ctaChildren}
              </CTAButton>
            </CTAWrapper>
          </BodyWrapper>
          {!isSmall && (
            <IconWrapper>
              <CloseIcon aria-label="Close" onClick={closeModal} />
            </IconWrapper>
          )}
        </Container>
      </Wrapper>
      {modal}
    </>
  )
}

export default withTheme(Tophat)
