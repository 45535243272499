/* eslint-disable prefer-destructuring */
import React from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react'

import { stringNotEmpty } from '@agnostack/lib-core'

import { StyledMarkdown, StyledMDX } from '../styled'

const RenderMarkdown = ({
  className,
  overrides,
  node,
}) => {
  const compiled = node?.compiled
  const content = node?.content || node?.rawBody || node?.rawMarkdownBody || node?.body || node?.html

  switch (true) {
    case stringNotEmpty(compiled): {
      return (
        <StyledMDX className={className}>
          <MDXProvider components={overrides}>
            {/* TODO: not sure if MDXRenderer still needs process env props?? */}
            <MDXRenderer>
              {compiled}
            </MDXRenderer>
          </MDXProvider>
        </StyledMDX>
      )
    }

    case stringNotEmpty(content): {
      return (
        <StyledMarkdown options={{ overrides }}>
          {content}
        </StyledMarkdown>
      )
    }

    default: {
      return null
    }
  }
}

export default RenderMarkdown
