import styled from 'styled-components'

import { Grid, Row, Col } from '@zendeskgarden/react-grid'

import { arrayIncludesAny } from '@agnostack/lib-utils-js'
import { arrayNotEmpty, ensureArray, ensureNumeric } from '@agnostack/lib-core'

import { SVGIcon } from '@agnostack/lib-utils-react'

export const LABELS = {
  UPDATED: 'updated',
  NEW: 'new',
  ADD_ON: 'addOn',
  PLATFORM_SPECIFIC: 'platform',
}

export const IconWrapper = styled(SVGIcon).attrs(() => ({
  size: 'small',
}))`
  margin-right: 2px;
`

export const FeatureBody = styled.div`
  ${({ icons, theme: { space } = {} }) => arrayNotEmpty(icons) && `
    margin-left: -${ensureNumeric(space.sm) * icons.length}px;
  `}

  ${({ labels }) => labels && `
    ${arrayIncludesAny(labels, [LABELS.NEW, LABELS.UPDATED]) ? `
      font-weight: 600;
    ` : ''}
    ${ensureArray(labels).includes(LABELS.UPDATED) ? `
      font-style: italic;
    ` : ''}
    ${ensureArray(labels).includes(LABELS.PLATFORM_SPECIFIC) ? `
      padding-left: 8px;
      &:after {
        content: '*';
        vertical-align: super;
        font-size: .7rem;
        padding-left: 2px;
      }
    ` : ''}
  `}
`

export const StyledGrid = styled(Grid)`
  ${({ isSmall, small, size }) => (isSmall || small || size === 'small') && `
    font-size: .7rem;
  `}
`

export const StyledRow = styled(Row)`
  justify-content: center;
`

export const PricingTier = styled(Col)`
  cursor: pointer;
  border-left: 0.5px solid ${({ theme }) => theme.colors.shadow2};
  ${({ selected, theme, format = 'dark' }) => selected && `
    margin-top: -2em;
    padding-top: 2em;
    padding-bottom: 3em;
    border-radius: 15px;
    color: ${theme.formats[format].color};
    background-color: ${theme.formats[format].background};
    box-shadow: 0 15px 50px ${theme.formats[format].background};
  `}
  &:nth-of-type(1) {
    border-left: none;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.calc(theme.breakpoints.md, 'max')}) {
    border-left: none;
    border-top: 0.5px solid ${({ theme }) => theme.colors.shadow2};
    padding: 2em;
    margin-top: 2em;
    &:nth-of-type(1) {
      border-top: none;
      margin-top: 0;
    }
  }
`

export const PricingWrapper = styled.div`
  margin-bottom: 0.5rem;
`

export const PricingCost = styled.div`
  font-size: 3em;
  line-height: 0.9;
`

export const PricingPerMonth = styled.span`
  font-size: 0.5em;
`

export const PricingPerAgent = styled.div`
  font-size: 0.75em;
`

export const PricingFeature = styled.div`
  padding: 0.6rem 0 0 0;
`

export const FeatureNote = styled.div`
  margin-top: 2rem;
  font-style: italic;
`

export const PricingNote = styled.div`
  margin-top: 1rem;
`

export const MinimumAgents = styled.div`
  font-size: 0.75em;
  font-style: italic;
`
