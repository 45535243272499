import React from 'react'
import { Tooltip } from '@zendeskgarden/react-tooltips'
import { Field } from '@zendeskgarden/react-forms'

import { ClipboardCheckedIcon, ClipboardUnCheckedIcon } from '@agnostack/components-assets'

import {
  SnippetWrapper,
  Code,
  ClipboardWrapper,
  IconButton,
} from './CodeSnippet.style'

export const STATUS = {
  DEFAULT: 'Copy to Clipboard',
  COPIED: 'Copied to Clipboard!',
}

const CodeSnippet = ({
  children,
  copied,
  clipboard = false,
  onCopy = () => console.log('onCopy'),
  inputRefCallback,
  buttonRefCallback,
  statusMessage,
  ...renderProps
}) => (
  <SnippetWrapper>
    {clipboard && (
      <ClipboardWrapper>
        <Tooltip content={statusMessage}>
          <IconButton
            ref={buttonRefCallback}
            tiny
            isPill={false}
            onClick={() => onCopy()}
          >
            {copied ? <ClipboardCheckedIcon /> : <ClipboardUnCheckedIcon />}
          </IconButton>
        </Tooltip>
      </ClipboardWrapper>
    )}
    <Field>
      <Code ref={inputRefCallback} {...renderProps} value={children} />
    </Field>
  </SnippetWrapper>
)

export default CodeSnippet
