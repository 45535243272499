/* eslint-disable no-restricted-syntax */
import createFlags from 'flag'

import { deepEqual } from '@agnostack/lib-utils-js'
import { arrayEmpty, ensureArray, ensureObject, objectEmpty } from '@agnostack/lib-core'

export const {
  FlagsProvider,
  useFlags: getUserFlags,
} = createFlags()

export const getFlags = () => getUserFlags()

// TODO!!!: support non-boolean flags
export const getEnabledFlags = (flags = getFlags()) => (
  [...new Set(
    Object.entries(ensureObject(flags)).reduce((
      _enabledFlags,
      [flag, value]
    ) => (
      (value !== true) ? _enabledFlags : [
        ..._enabledFlags,
        flag
      ]
    ), [])
  )]
)

export const matchNone = (componentFlags, userFlags = getFlags()) => {
  if (arrayEmpty(componentFlags)) {
    return true
  }

  // eslint-disable-next-line no-use-before-define
  return !matchAny(componentFlags, userFlags)
}

export const matchAny = (componentFlags, userFlags = getFlags()) => {
  if (arrayEmpty(componentFlags)) {
    return true
  }

  if (objectEmpty(userFlags)) {
    return false
  }

  let matchedOne = false
  // NOTE: intentionally using for/of loop instead of map so we can break to exit quickly soon as matched
  for (const componentFlag of componentFlags) {
    if (componentFlag && typeof componentFlag === 'object') {
      for (const [key, value] of Object.entries(componentFlag)) {
        if (deepEqual(userFlags[key], value)) {
          // eslint-disable-next-line max-depth
          if (!matchedOne) {
            matchedOne = true
            break
          }
        }
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (componentFlag in userFlags && userFlags[componentFlag]) {
        if (!matchedOne) {
          matchedOne = true
          break
        }
      }
    }
  }

  return matchedOne
}

export const matchAll = (componentFlags, userFlags = getFlags()) => {
  if (arrayEmpty(componentFlags)) {
    return true
  }

  if (objectEmpty(userFlags)) {
    return false
  }

  let matchedAll = true
  // NOTE: intentionally using for/of loop instead of map so we can break to exit quickly soon as matched
  for (const componentFlag of componentFlags) {
    if (componentFlag && typeof componentFlag === 'object') {
      for (const [key, value] of Object.entries(componentFlag)) {
        if (!deepEqual(userFlags[key], value)) {
          matchedAll = false
          break
        }
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (!Object.keys(userFlags).includes(componentFlag) || userFlags[componentFlag] === false) {
        matchedAll = false
        break
      }
    }
  }

  return matchedAll
}

export const getMatched = (flagged, { userFlags, matchType = 'any' } = {}) => {
  const componentFlags = ensureArray(flagged)

  let matched
  switch (matchType) {
    case 'none':
      matched = matchNone(componentFlags, userFlags)
      break

    case 'all':
      matched = matchAll(componentFlags, userFlags)
      break

    case 'any':
    default:
      matched = matchAny(componentFlags, userFlags)
      break
  }

  return matched
}
