/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import humanizeDuration from 'humanize-duration'
import storage from 'local-storage-fallback'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import { window } from 'browser-monads-ts'

import { useDimensions, useInterval } from '@agnostack/lib-utils-react'
import { delay, hashCode } from '@agnostack/lib-utils-js'
import {
  random,
  stringEmpty,
  ensureObject,
  ensureNumeric,
  ensureDateTime,
  toUnixInteger,
  toFormatted,
} from '@agnostack/lib-core'

import { useContextGlobal, GLOBAL_ACTIONS, TITLE_LIVE_DEMO_REGISTER } from '../util'

import DemoModal from '../components/molecules/DemoModal'
import Counter from '../components/atoms/Counter'
import Heading from '../components/atoms/Heading'

const COUNTDOWN_THRESHOLD_DAYS = 3
const DEMO_WEEKS_MAX = 3
const DEMO_SPOT_MINIMUM = 2
const DEMO_SPOT_MAXIMUM = 27
const STORAGE_KEY_DEMO_SPOTS = 'demo-spots'

const StyledCounter = styled(Counter)`
  padding-right: .5em;
`

const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: 'shortEn',
  languages: {
    shortEn: {
      d: () => 'D',
      h: () => 'H',
      m: () => 'M',
    },
  },
})

const getDaysOfWeek = (start, end, isoDay) => {
  const days = []
  let next = start.startOf('day').plus({ days: 1 })

  while ((next >= start) && (next <= end)) {
    if (next.weekday === isoDay) {
      days.push(next)
    }
    next = next.plus({ days: 1 })
  }

  return days
}

const daysOfMonth = (
  startDateTime = ensureDateTime(),
  isoDay = 1
) => (
  getDaysOfWeek(
    startDateTime.startOf('month'),
    startDateTime.endOf('month'),
    isoDay
  )
)

const getNextNthDaysOfWeek = (
  startDateTime = ensureDateTime(),
  isoDay = 1,
  nth = 1
) => {
  const currentMonthDays = daysOfMonth(startDateTime, isoDay)
    .filter((day, index) => (
      day && index % nth === 0 && day > startDateTime
    ))
  const nextMonthDays = daysOfMonth(startDateTime.plus({ months: 1 }), isoDay)
    .filter((day, index) => (
      day && index % nth === 0 && day > startDateTime
    ))

  return [...currentMonthDays, ...nextMonthDays]
}

export const useDemo = ({ breakpoints, pathname, title: _title }) => {
  const title = _title || `${TITLE_LIVE_DEMO_REGISTER}!`
  const [, dispatch] = useContextGlobal()

  const [{ width }] = useDimensions(window)
  const [demoBooked, setDemoBooked] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const [now, setNow] = useState(ensureDateTime())

  const guid = useMemo(() => (
    pathname ? `${hashCode(pathname)}-demo` : uuidv4()
  ), [pathname])

  const isSmall = useMemo(() => {
    const { sm: breakpointPx = '', numeric = () => width } = ensureObject(breakpoints)
    const breakpoint = numeric(breakpointPx)
    return (width < breakpoint)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width])

  // NOTE: countdown timer every minute
  useInterval(() => setNow(ensureDateTime()), 60000)

  const { nextEventDateTime, nextEventTimestamp, nextEventLocalized, nextEventLocalizedTimestamp } = useMemo(() => {
    const _nextEventDateTime = getNextNthDaysOfWeek(now, 4, DEMO_WEEKS_MAX)[0]
    const _nextEventTimestamp = toUnixInteger(_nextEventDateTime)
    const _nextEventLocalized = _nextEventDateTime.plus({ hours: 8 }) // TODO: confirm 8A EST?
    const _nextEventLocalizedTimestamp = toUnixInteger(_nextEventLocalized)

    return {
      nextEventDateTime: _nextEventDateTime,
      nextEventTimestamp: _nextEventTimestamp,
      nextEventLocalized: _nextEventLocalized,
      nextEventLocalizedTimestamp: _nextEventLocalizedTimestamp,
    }
  }, [now])

  const previousDemoData = useMemo(() => {
    if (nextEventTimestamp) {
      try {
        const _previousDemoData = storage.getItem(STORAGE_KEY_DEMO_SPOTS)

        return _previousDemoData ? JSON.parse(_previousDemoData) : {}
      } catch (info) {
        console.info('Ignoring storage error', info)
      }
    }
    return {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextEventTimestamp, demoBooked])

  const spotsRemaining = useMemo(() => {
    try {
      const {
        spots: previousSpots,
        timestamp: previousTimestamp,
      } = ensureObject(previousDemoData)

      if (stringEmpty(previousTimestamp)) {
        const spots = random(DEMO_SPOT_MAXIMUM, DEMO_SPOT_MINIMUM)
        storage.setItem(STORAGE_KEY_DEMO_SPOTS, JSON.stringify({
          spots,
          timestamp: nextEventTimestamp,
        }))

        return spots
      }

      const daysSinceLastVisit = Math.round(ensureDateTime(previousTimestamp).diff(nextEventDateTime).as('days'))

      if ((daysSinceLastVisit > (7 * DEMO_WEEKS_MAX)) || (daysSinceLastVisit < 0)) {
        const spots = random(DEMO_SPOT_MAXIMUM, DEMO_SPOT_MINIMUM)
        storage.setItem(STORAGE_KEY_DEMO_SPOTS, JSON.stringify({
          spots,
          timestamp: nextEventTimestamp,
        }))

        return spots
      }

      if (daysSinceLastVisit > 1) {
        const spots = Math.max(ensureNumeric(previousSpots) - random(3), DEMO_SPOT_MINIMUM)
        storage.setItem(STORAGE_KEY_DEMO_SPOTS, JSON.stringify({
          spots,
          timestamp: nextEventTimestamp,
        }))

        return spots
      }

      return previousSpots
    } catch (info) {
      console.info('Ignoring storage error', info)
    }

    return DEMO_SPOT_MINIMUM
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousDemoData])

  const timeUntilNextEvent = useMemo(() => (
    shortEnglishHumanizer(
      nextEventLocalized.diff(now).as('milliseconds'),
      {
        units: ['d', 'h', 'm'],
        delimiter: ', ',
        spacer: '',
        round: true,
      }
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [nextEventLocalized])

  const ctaTitle = useMemo(() => (
    (nextEventLocalized.diff(now).as('days') < COUNTDOWN_THRESHOLD_DAYS)
      ? `Starting in ${timeUntilNextEvent}`
      : `${toFormatted({ value: nextEventLocalized, format: isSmall ? 'ccc FF ZZZZ' : 'cccc FFF' })}`
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [isSmall, now, nextEventLocalized])

  const decrementSpot = useCallback((decrement = 1) => {
    const spots = Math.max(ensureNumeric(spotsRemaining) - decrement, DEMO_SPOT_MINIMUM)

    if (spots >= DEMO_SPOT_MINIMUM) {
      storage.setItem(STORAGE_KEY_DEMO_SPOTS, JSON.stringify({
        spots,
        timestamp: nextEventTimestamp,
      }))
    }

    if (spots > DEMO_SPOT_MINIMUM) {
      setDemoBooked(true)
    }
  }, [spotsRemaining, nextEventTimestamp])

  useEffect(() => {
    const initialLoad = async () => {
      await delay(60)
      setOpen(true)
      await delay(Math.max(random(12000), 3000))
      decrementSpot()
    }

    initialLoad()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const resetDemo = async () => {
      await delay(1000)
      setDemoBooked(false)
    }

    if (demoBooked) {
      resetDemo()
    }
  }, [demoBooked])

  const ctaChildren = useMemo(() => (
    <>
      <StyledCounter count={spotsRemaining} />
      spots remaining
    </>
  ), [spotsRemaining])

  const modal = useMemo(() => {
    if (!ctaChildren || !guid || !nextEventLocalizedTimestamp) {
      return null
    }

    return (
      <DemoModal
        id={guid}
        timestamp={nextEventLocalizedTimestamp}
        onSuccess={decrementSpot}
      >
        <Heading tag="4">
          {ctaChildren}
        </Heading>
      </DemoModal>
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctaChildren, guid, nextEventLocalizedTimestamp])

  return {
    guid,
    isOpen,
    isSmall,
    demoBooked,
    spotsRemaining,
    ctaChildren,
    ctaTitle,
    title,
    modal,
    closeModal: () => setOpen(false),
    openModal: () => dispatch({
      type: GLOBAL_ACTIONS.DISPATCH_MODAL,
      id: guid,
      visible: true,
      title,
    }),
  }
}
