/* eslint-disable eqeqeq */
import React, { useMemo, useState } from 'react'
import dot from 'dot-object'

import { combineCommas } from '@agnostack/lib-core'
import { delay, safeStringify } from '@agnostack/lib-utils-js'

import TreeView from '../TreeView'
import { copyToClipboard } from '../clipboard'
import { CopyWrapper, DisplayValue, StyledSVGIcon } from './CopyableTreeView.style'

const CopyableTreeValue = ({
  displayValue = null,
  valueOnly = false,
  rawValue,
  keyPath,
  children,
  Icon,
  onCopied = async () => {},
}) => {
  const [copiedText, setCopiedText] = useState()

  const { copyableValue } = useMemo(() => {
    let _objectPath
    let _objectValue = rawValue
    try {
      if (!valueOnly) {
        _objectPath = combineCommas(keyPath).reverse().join('.')
        _objectValue = dot.str(_objectPath, rawValue, {}) // TODO: handle if rawValue is not a string or object???
      }
    } catch (ignore) {
      console.log('Ignoring error preparing value', ignore)
      return undefined
    }

    return {
      objectPath: _objectPath,
      objectValue: _objectValue,
      copyableValue: safeStringify(_objectValue, null, 2),
    }
  }, [keyPath, rawValue])

  const handleCopy = async (e) => {
    e?.stopPropagation?.()
    setCopiedText()

    await copyToClipboard(copyableValue).then(async (_copiedText) => {
      setCopiedText(_copiedText)
      await delay(200)
      await onCopied(_copiedText)
      await delay(1000)
      setCopiedText()
    })
  }

  if (copyableValue == undefined) {
    return displayValue
  }

  return (
    <CopyWrapper title={children}>
      <StyledSVGIcon isCopied={copiedText}>
        <Icon isCopied={copiedText} onClick={handleCopy} />
      </StyledSVGIcon>
      <DisplayValue>{displayValue}</DisplayValue>
    </CopyWrapper>
  )
}

const CopyableTreeView = ({ Icon, valueOnly, onCopied, ...renderProps }) => {
  const isCopyable = useMemo(() => (
    (Icon != undefined) && (navigator?.clipboard?.writeText != undefined)
  ), [(Icon != undefined)])

  if (!isCopyable) {
    return (
      <TreeView {...renderProps} />
    )
  }

  return (
    <TreeView
      labelRenderer={(keyPath) => (
        <i>{keyPath[0]}</i>
      )}
      {...renderProps}
      valueRenderer={(displayedValue, rawValue, ...keyPath) => (
        <CopyableTreeValue
          Icon={Icon}
          displayValue={displayedValue}
          valueOnly={valueOnly}
          keyPath={keyPath}
          rawValue={rawValue}
          onCopied={onCopied}
        />
      )}
      // For objects/arrays:
      getItemString={(_type, rawValue, _itemType, itemString, ...keyPath) => (
        <CopyableTreeValue
          Icon={Icon}
          valueOnly={valueOnly}
          displayValue={itemString}
          keyPath={keyPath}
          rawValue={rawValue}
          onCopied={onCopied}
        />
      )}
    />
  )
}

export default CopyableTreeView
