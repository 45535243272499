import React, { createContext } from 'react'
import storage from 'local-storage-fallback'

import { stringNotEmpty, ensureObject, ensureDateTime } from '@agnostack/lib-core'
import {
  useContextState,
  ContextDebugger,
  ReducerProvider,
  GLOBAL_DISPATCH_TYPES,
} from '@agnostack/lib-utils-react-stateful'

import { GLOBAL_PARAMS, LISTING_NAMES } from './constants'

const GlobalContext = createContext()

export const GLOBAL_ACTIONS = {
  ...GLOBAL_DISPATCH_TYPES,
  ACTIVE_LISTING: 'ACTIVE_LISTING',
  ACTIVE_FORMAT: 'ACTIVE_FORMAT',
  DISPATCH_MODAL: 'DISPATCH_MODAL',
}

export const MODAL_TYPES = {
  IFRAME: 'iframe',
  DEFAULT: 'modal',
}

const initialState = {
  activeFormat: 'medium',
  slidable: true,
}

const globalReducer = (_state, _action) => {
  const { type, ...action } = ensureObject(_action)

  switch (type) {
    case GLOBAL_ACTIONS.ACTIVE_FORMAT: {
      const { activeFormat } = action
      return {
        ..._state,
        activeFormat,
      }
    }

    case GLOBAL_ACTIONS.DISPATCH_MODAL: {
      const { id, title, visible } = action
      const { modalsState = {} } = _state
      const { [id]: modalState } = modalsState

      const storageKey = `modal-${id}-viewed`
      let previousTimestamp
      let currentTimestamp

      try {
        previousTimestamp = storage.getItem(storageKey)
        currentTimestamp = ensureDateTime()
        if (visible) {
          storage.setItem(storageKey, currentTimestamp)
        }
      } catch (info) {
        console.info('Ignoring storage error', info)
      }

      return {
        ..._state,
        slidable: !visible,
        modalsState: {
          ...modalsState,
          [id]: {
            ...modalState,
            visible,
            title,
            previousTimestamp,
            currentTimestamp,
          },
        },
      }
    }

    case GLOBAL_ACTIONS.ACTIVE_LISTING: {
      const { [GLOBAL_PARAMS.LISTING]: activeListingName } = _state
      const { payload: listingName } = action

      if (stringNotEmpty(listingName) && (!LISTING_NAMES.includes(listingName) || (activeListingName === listingName))) {
        return _state
      }

      return {
        ..._state,
        [GLOBAL_PARAMS.LISTING]: listingName,
      }
    }

    default: {
      return undefined
    }
  }
}

export const useContextGlobal = () => (
  useContextState(GlobalContext)
)

export const GlobalContextProvider = ({ children, ...initialValue }) => (
  <ReducerProvider
    contextProvider={GlobalContext.Provider}
    reducer={globalReducer}
    value={{
      ...initialState,
      ...initialValue,
    }}
  >
    {children}
  </ReducerProvider>
)

export const GlobalContextDebugger = ({ enabled = true, ...renderProps }) => {
  const [globalContextState] = useContextGlobal()

  return (
    <ContextDebugger
      {...renderProps}
      contextState={globalContextState}
    />
  )
}
