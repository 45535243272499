import React, { useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { globalHistory } from '@reach/router'

import { Button } from '@zendeskgarden/react-buttons'

import { hashCode } from '@agnostack/lib-utils-js'

import {
  useContextGlobal,
  GLOBAL_ACTIONS,
  TITLE_SCHEDULE_DEMO,
} from '../../../util'
import ScheduleModal from '../../molecules/ScheduleModal'

const ScheduleButton = ({ children = TITLE_SCHEDULE_DEMO, ...renderProps }) => {
  const [, dispatch] = useContextGlobal()

  const guid = useMemo(() => (
    globalHistory?.location?.pathname
      ? `${hashCode(globalHistory.location.pathname)}-schedule`
      : uuidv4()
  ), [])

  return (
    <>
      <ScheduleModal id={guid} />
      <Button
        isPrimary
        {...renderProps}
        onClick={() => {
          dispatch({
            type: GLOBAL_ACTIONS.DISPATCH_MODAL,
            id: guid,
            visible: true,
            title: children,
          })
        }}
      >
        {children}
      </Button>
    </>
  )
}

export default ScheduleButton
