import React, { useEffect, useState, useRef } from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'

// TODO: explore importing Dropdown from @agnostack/components-atoms
import { Item, Field, Dropdown } from '@zendeskgarden/react-dropdowns'

import { delay } from '@agnostack/lib-utils-js'
import { stringNotEmpty, arrayNotEmpty } from '@agnostack/lib-core'
import { SearchIcon } from '@agnostack/components-assets'

import { useSearch } from '../../../hooks'
import {
  DropdownWrapper,
  StyledSVGIcon,
  StyledAutocomplete,
  SearchWrapper,
  SearchItem,
  SearchMenu,
} from './SearchBox.style'

const SearchBox = ({ showSearch, onShowSearch, ...renderProps }) => {
  const inputRef = useRef()

  const { localSearchPages } = useStaticQuery(
    graphql`
      query {
        localSearchPages {
          index
          store
        }
      }
    `
  )

  const [searchQuery, setSearchQuery] = useState()
  const { prioritizedResults, results } = useSearch(searchQuery, localSearchPages)

  const setShowSearch = (value) => {
    onShowSearch?.(value)
  }

  const handleSearchToggle = () => {
    setShowSearch(!showSearch)
  }

  const handleSelect = (path) => {
    setShowSearch(false)
    navigate(path)
  }

  useEffect(() => {
    const focus = async () => {
      await delay(20)
      if (inputRef && inputRef.current) {
        inputRef.current.addEventListener('blur', () => {
          setShowSearch(false)
        }, { once: true })

        if (showSearch) {
          inputRef.current.click()
        }
      }
    }

    focus()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef, showSearch])

  return (
    <SearchWrapper data-active={showSearch} {...renderProps}>
      {showSearch && (
        <DropdownWrapper>
          <Dropdown
            inputValue={searchQuery}
            onSelect={handleSelect}
            onInputValueChange={setSearchQuery}
            downshiftProps={{ defaultHighlightedIndex: 0 }}
          >
            <Field>
              <StyledAutocomplete inputRef={inputRef}>
                {searchQuery}
              </StyledAutocomplete>
              {stringNotEmpty(searchQuery) && (
                <SearchMenu>
                  {(arrayNotEmpty(prioritizedResults) || arrayNotEmpty(results)) ? (
                    <>
                      {prioritizedResults.map(({ id, title, subtitle, path }) => (
                        <SearchItem key={id} value={path} highlight>
                          {[title, subtitle].filter(stringNotEmpty).join(' ')}
                        </SearchItem>
                      ))}
                      {results.map(({ id, title, subtitle, path }) => (
                        <SearchItem key={id} value={path}>
                          {[title, subtitle].filter(stringNotEmpty).join(' ')}
                        </SearchItem>
                      ))}
                    </>
                  ) : (
                    <Item disabled>No matches found</Item>
                  )}
                </SearchMenu>
              )}
            </Field>
          </Dropdown>
        </DropdownWrapper>
      )}
      <StyledSVGIcon
        data-active={showSearch}
        onMouseDown={handleSearchToggle}
      >
        <SearchIcon />
      </StyledSVGIcon>
    </SearchWrapper>
  )
}

export default SearchBox
